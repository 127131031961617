import React, { Component } from 'react';
import { browserHistory, Router } from 'react-router';
import { connect, Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { SocketProvider } from 'socket.io-react';
import io from 'socket.io-client';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { userUpdate, userLogout } from 'store/user';
import { updateDataArray } from 'store/dataArray';
import { setDefaultDishes } from 'store/defaultDishes';
import { setCustomPlans } from 'store/customPlans';
import { setCustomPlanSides } from 'store/customPlanSides'; 
import { setSettings } from 'store/settings';
import { setRemainDeliveries } from 'store/remainDeliveries';
import rstr from 'randomstring';

const hosts = { 
  production: 'https://healthyme.loseweight.ae',
  development: 'https://healthyme.loseweight.ae',
  default: 'https://healthyme.loseweight.ae'
};
const socketServerLink = hosts[process.env.NODE_ENV] || hosts.default;
const browserId = rstr.generate(16).toUpperCase();

let socket = null;

class App extends Component {
  constructor(props) {
    super(props);
    this.events = [];
  }

  socketClientCheck = ({ type, data }) => {
    const { updateUser } = this.props;
    if (['setUser', 'update'].includes(type)) {
      updateUser({ ...data, cc: true });
    } else {
      this.props.logout({ browserId: rstr.generate(16).toUpperCase() });
      browserHistory.push('/sign-in');
    }
  };

  socketLogin = ({ type, data }) => {
    if (type === 'logoutOk') {
      const { logout, user } = this.props;
      const { browserId } = data;
      user.browserId === browserId && logout({ browserId: rstr.generate(16).toUpperCase() });
    }
  };

  socketGetArrays = action => {
    if (action.type === 'success') {
      this.props.updateDataArray(action.data);
    } else {
      console.log(action.data);
    }
  };

  defaultDishesListener = ({ type, data }) => {
    if (type === 'getOk') {
      this.props.setDefaultDishes(data['defaultDishes']);
    } else if (type === 'getErr') {
      console.error(data);
    }
  };

  socketCustomPlan = ({ type, data }) => {
    if (type === 'getOk') {
      this.props.setCustomPlans(data.customPlans);
    } else if (type === 'getErr') {
      console.error(data);
    }
  };
  socketCustomPlanSides = ({ type, data }) => {
    if (type === 'getOk') {
      this.props.setCustomPlanSides(data.customPlanSides);
    } else if (type === 'getErr') {
      console.error(data);
    }
  };
  settingsListener = ({ type, status, data }) => {
    const { setSettings } = this.props;
    if (type === 'get' && status === 'ok') {
      setSettings(data);
    }
   
  };

  orderListener = ({ type, status, data }) => {
    if (type === 'remainDeliveries' && status === 'ok') {
      this.props.setRemainDeliveries(data);
    }
  };

  componentWillMount() {
    browserHistory.listen(() => {
      document.querySelector('body').scrollIntoView();
    });
    socket = io(socketServerLink, {
      transports: ['websocket']
    });
    
    socket.on('connect', () => {
      const { user, updateUser } = this.props;
      socket.on('client_check', this.socketClientCheck);
      socket.on('login', this.socketLogin);
      socket.on('get_arrays', this.socketGetArrays);
      socket.on('custom_plan', this.socketCustomPlan);
      socket.on('custom_plan_sides', this.socketCustomPlanSides);
      socket.on('default_dishes', this.defaultDishesListener);
      socket.on('settings', this.settingsListener);
      socket.on('order', this.orderListener);
      const { token, browserId } = user;
      if (token) {
        socket.emit('client_check', { type: 'get', data: { token, browserId } });
        updateUser({ cc: false });
      }
      socket.emit('settings', { type: 'get', data: {} });
    });
  }

  componentWillUnmount() {
    socket.removeListener('default_dishes', this.defaultDishesListener);
    socket.removeListener('custom_plan', this.socketCustomPlan);
    socket.removeListener('custom_plan_sides', this.socketCustomPlanSides);
    socket.removeListener('client_check', this.socketClientCheck);
    socket.removeListener('login', this.socketLogin);
    socket.removeListener('get_arrays', this.socketGetArrays);
    socket.removeListener('settings', this.settingsListener);
    socket.removeListener('order', this.orderListener);
    socket.disconnect();
  }

  shouldComponentUpdate() {
    return false;
  }

  componentDidMount() {
    // const obs = new MutationObserver(() => {
    // const html = document.querySelector('html');
    // const { height } = html.getBoundingClientRect();
    // if (window.innerHeight > height) {
    //   html.style.setProperty('height', `${window.innerHeight}px`);
    // }
    // });
    // obs.observe(document.querySelector('html'), { childList: true, subtree: true });
    this.props.updateUser({ browserId });
  }

  render() {
    return (
      <PersistGate persistor={this.props.persistor}>
        <SocketProvider socket={socket}>
          <Provider store={this.props.store}>
            <Router history={browserHistory} children={this.props.routes} />
          </Provider>
        </SocketProvider>
      </PersistGate>
    );
  }
}

App.propTypes = {
  store: PropTypes.object.isRequired,
  persistor: PropTypes.object.isRequired,
  routes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  setDefaultDishes: PropTypes.func,
  setCustomPlans: PropTypes.func,
  setCustomPlanSides: PropTypes.func,
  updateUser: PropTypes.func,
  logout: PropTypes.func,
  setSettings: PropTypes.func,
  setRemainDeliveries: PropTypes.func,
  updateDataArray: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
  logout: (pl) => dispatch(userLogout(pl)),
  updateUser: user => dispatch(userUpdate(user)),
  updateDataArray: arr => dispatch(updateDataArray(arr)),
  setCustomPlans: arr => dispatch(setCustomPlans(arr)),
  setCustomPlanSides: arr => dispatch(setCustomPlanSides(arr)),
  setRemainDeliveries: arr => dispatch(setRemainDeliveries(arr)),
  setDefaultDishes: obj => dispatch(setDefaultDishes(obj)),
  setSettings: obj => dispatch(setSettings(obj))
});

const mapStateToProps = state => ({
  user: state.user,
  dataArray: state.dataArray
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
