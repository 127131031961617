import React,{ Component } from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-number-input';
import  'react-phone-number-input/style.css';
class PhoneNumberInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labelClass:''
    };
  }
   onCountryChange = (country) =>{
    console.log(country);
  }
   onFocusInput =(event)=>{
    this.setState({labelClass:'focusInput'});
  }
   onBlurInput =(event)=>{
    this.setState({labelClass:''});
  }
  render() {
  const {
    classError,
    phoneProps,
    errors
  } = this.props;
  const { labelClass } = this.state;
  return (
    <div className={`phoneInput ${labelClass} `}>
      {/* {labelText && <label className='labelText'>{labelText}</label>} */}
      <PhoneInput
      onBlur={this.onBlurInput}
      onFocus={this.onFocusInput}
       onCountryChange={this.onCountryChange}
        {...phoneProps}
     /> 
     {phoneProps.name in errors && <span className={classError}>{errors[phoneProps.name]}</span>}
    </div>
  );
  }
}

PhoneNumberInput.propTypes = {
  classes: PropTypes.object,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  phoneProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool
};

export default PhoneNumberInput;