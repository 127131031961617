import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import RadioInput from '../../components/RadioInput/indexnew';
import PaperButton from 'react-paper-button';
class RenewAlert extends Component {
  constructor(props){
    super(props);
    this.state={
      sameMenu:'samemenu'
    }
}
renderModal = ()=>{
  const radioOptions = [
    { title: 'Renew my plan with my same menu repeated', value: 'samemenu' },
    { title: 'Renew my plan with the default dishes and then I will change dishes', value: 'defaultmenu' }
  ];
  const { sameMenu } = this.state;
  const { setModal,clearModal } = this.props;
  setModal({ headLine: 'Renew my plan', message: (
  <div className={'text-center'}>
          <div className={'renew-content'}>
              <div className={''}>
                <RadioInput name='renewmethod' datas={radioOptions} value={sameMenu} onChange={(name,value)=>{this.setState({sameMenu:value});}} />
              </div>
          </div>
          <div className={'renew-footer'}>
                <PaperButton
                  className={'button-small transition continue'}
                  onClick={() => { 
                    clearModal();
                    // navigation.navigate('BuildMenuRenewplan',{sameMenu});
                    browserHistory.push('/renewplan/'+sameMenu);
                }}
                >
                  <span>Continue</span>
                </PaperButton>
                <PaperButton
                  className={'button-small transition back-btn'}
                  onClick={() =>  clearModal()}
                >
                  <span>Cancel</span>
                </PaperButton>
          </div>
          </div>)});
}
render() {
  const { diff ,checkout } = this.props;
  return (
      <div className={'col-12 text-center mt-1 mb-1'}>
          <div className="col-12 text-center">
                              <span className="days-left-text">Your current meal plan expires in {diff} {'days'}</span><br/>
                              <button className="btn gold-button gold-renew animated infinite pulse delay-4s" onClick={() => {
          if(checkout!=undefined && checkout){
            //navigation.navigate('BuildMenuRenewplan',{sameMenu:false,checkout_id:checkout?.id});
            browserHistory.push('/checkout');
          }else{
            this.renderModal();
          }
          }}>Renew Now</button>
            </div>
      </div>
   );
   }
}; 
export default RenewAlert;