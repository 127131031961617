import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import Day from './Day';
import { setMealPlan } from 'store/mealPlan';
import { setPlanParams } from 'store/planParams';
import { setModal, updateModal, clearModal } from 'store/modal';
import PaperButton from 'react-paper-button';
import SelectValidation from 'components/SelectValidation';
import InputCalendar from 'components/InputCalendar';
import CheckBox from 'components/CheckBox';
import ToolTip from 'components/ToolTip';
import Promo from 'assets/promo.jpg';
import { socketConnect } from 'socket.io-react';
import { browserHistory } from 'react-router';

require('moment-weekday-calc');

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: this.props.date,
      acceptTC: false,
      resumeDate: false,
      xyz: false,
      showdetails: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { date: prevDate } = this.props;
    const { date } = nextProps;
    if ((!prevDate && date) || (date && !date.isSame(prevDate))) {
      this.setState({ date });
    }
  }

  componentDidMount() {
    this.props.onReady();
  }

  orderPromo() {
    const {user, setModal} = this.props;
    this.props.socket.emit('profile', {type: 'getForDashboard', data: {
          'subtype': 'alertMail', 
          'userId': user.user_id,
          'changes': 'Ordered Promo Package from the banner of the "edit mealplan" page',
        } 
    });

    setModal({ headLine: 'Promo Package', message: 'Our team received a notification, that you want to add the package to your mealplan. We will be in touch with you. Thank you!'});
  }

  /*onChangeDate = diff => {
    const { date } = this.state;
    const { period, mealPlan } = this.props;
    const dates = Object.keys(mealPlan);
    const newDate = date.clone().add(diff, 'week');
    if (diff < 0) {
      newDate.endOf('week');
    }
    if (newDate.isSameOrAfter(period.from) && newDate.isBefore(period.to)) {
      const from = newDate.clone().startOf('week').unix();
      const to = newDate.clone().endOf('week').unix();
      if (dates.filter(el => +el >= from && +el < to).length) {
        this.props.onChangeDate(newDate.startOf('week'));
      } else {
        this.onChangeDate(diff > 0 ? diff + 1 : diff - 1);
      }
    }
  };*/

  /*replicate = () => {
    const { date } = this.state;
    const { mealPlan, period, setMealPlan, setPlanParams, planParams } = this.props;
    let plan = [...mealPlan.filter(el => el.date < date.clone().add(1, 'week').unix())];
    mealPlan
      .filter(el => moment.unix(el.date).isSameOrAfter(date) && moment(el.date * 1000).isBefore(date.clone().add(1, 'week')))
      .map(el => {
        const newDate = moment.unix(el.date).add(1, 'week');
        while (newDate.isSameOrAfter(period.from) && newDate.isBefore(period.to)) {
          plan = [
            ...plan,
            ...[{
              date: newDate.unix(),
              type: el.type,
              value: el.value
            }]
          ];
          newDate.add(1, 'week');
        }
        setPlanParams({ ...planParams, modified: true });
        setMealPlan(plan);
      });
  };*/

  submit = () => {
    const { period, mealPlan, dataArray, planParams, skipped } = this.props;
    const plan = dataArray['planList'] && dataArray['planList'][planParams['mealPlan']];
    if (Object.keys(mealPlan).filter(date => !(date in skipped)).length === +plan.count) {
      let date = period.from.clone();
      const type = planParams['mealType'];
      const includesDay = Array(31).fill(0).map((el, key) => key).filter(el => !plan.excludeWeekDay.includes(el));
      let freeDate = [];
      while (date.isSameOrBefore(period.to)) {
        const unix = date.unix();
        if (mealPlan[unix] && Object.keys(mealPlan[unix]).length !== type.length) {
          freeDate = [...freeDate, unix];
        }
        date = date.addWeekdaysFromSet(1, includesDay);
      }
      if (freeDate.length) {
        this.props.setModal({ message: 'Please fill in all menu to place your order' });
      } else {
        this.setModal('place');
      }
    } else {
      this.props.setModal({ message: 'Please fill in all menu to place your order.' });
    }
  };

  recalculateDates = (date, callback, includesDay) => {
    date = moment.unix(date);
    const { mealPlan, excludedDates } = this.props;
    let start = moment().utc().addWeekdaysFromSet(2, includesDay, excludedDates).startOf('day');
    const startWOHolidays = moment().utc().addWeekdaysFromSet(2, includesDay).startOf('day');
    if (startWOHolidays.isSameOrBefore(start)) {
      start = moment().utc().addWeekdaysFromSet(1, includesDay, excludedDates).subtract(1, 'd').startOf('day');
    }
    const startUnix = start.unix();
    let pastDates = {};
    const oldDates = Object.keys(mealPlan).reduce((acc, cur) => {
      if (+cur > startUnix) {
        return { ...acc, [+cur]: mealPlan[+cur] };
      } else {
        pastDates = { ...pastDates, [+cur]: mealPlan[+cur] };
        return acc;
      }
    }, {});
    const dif = date.day() - moment.unix(Object.keys(mealPlan)[0]).day();
    const values = Object.values(oldDates);
    let reordered = [...values];
    if (dif < 0) {
      reordered = [...values.splice(dif), ...values];
    } else if (dif > 0) {
      const tmp = values.splice(dif);
      reordered = [...tmp, ...values];
    }
    const result = reordered.reduce((acc, cur, idx) => {
      const curd = date.clone().utc().addWeekdaysFromSet(idx, includesDay, excludedDates);
      const timestamp = curd.unix();
      return { ...acc, [timestamp]: cur };
    }, {});
    this.props.setMealPlan({ ...pastDates, ...result });
    callback(date.utc());
  };

  componentDidUpdate(prevProps, prevState) {
    const { acceptTC, resumeDate } = this.state;
    const { acceptTC: prevAcceptTC, resumeDate: prevResumeDate } = prevState;
    const { deliverySlot } = this.props.planParams;
    const { deliverySlot: prevDeliverySlot } = prevProps.planParams;
    if (acceptTC !== prevAcceptTC || resumeDate !== prevResumeDate || deliverySlot !== prevDeliverySlot) {
      this.props.updateModal({
        message: this.getModalContent(this.modalType)
      });
    }
  }

  renderPauseModal = () => {
    const { dataArray, planParams, locale, pauseOrder, excludedDates } = this.props;
    const { resumeDate } = this.state;
    const { slotsList, planList } = dataArray;
    const deliveryItems = (slotsList || []).reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {});
    const { deliverySlot, slot_id, mealPlan: planId } = planParams;
    const excluded = (planList && planList[planId] && planList[planId]['excludeWeekDay']) || [];
    const includesDay = Array(31).fill(0).map((el, key) => key).filter(el => !excluded.includes(el));
    const changeDeliverySlot = (name, value) => {
      if (+value) {
        this.props.setPlanParams({ ...planParams, [name]: +value, modified: true });
      }
    };
    const today = moment();
    !today.isUTC() && today.utc();
    today.startOf('day');
    let newDate = today.addWeekdaysFromSet(3, includesDay, excludedDates);
    const startWOHolidays = moment().utc().addWeekdaysFromSet(2, includesDay).startOf('day');
    if (startWOHolidays.isSameOrBefore(newDate)) {
      newDate = moment().utc().addWeekdaysFromSet(1, includesDay, excludedDates).startOf('day');
    }
    const renderDay = (props, curd) => {
      if (!curd.isBetween(newDate, newDate.clone().add(3, 'months'), null, '[]') || excluded.includes(curd.day()) || excludedDates.includes(curd.format('YYYY-MM-DD'))) {
        props.className = `${props.className} rdtDisabled`;
        delete props.onChange;
        delete props.onClick;
      } else if (curd.isSame(resumeDate || newDate)) {
        props.className += ' selected';
      }
      return <td {...props}>{curd.date()}</td>;
    };
    return (
      <div className='text-center modal-message'>
        <div className='row'>
          <div className='pl-2 pr-2 w-100 text-center'>
            <p className='text-center'>Choose a day to start or resume shipping.</p>
          </div>
          <div className='w-75 mb-4 mt-2 mr-auto ml-auto text-left'>
            <span className='req'>Start/Resume date:</span>
            <div className='cal-holder search-profile-main-cal form-input calendar-input pointer absolute-cal e'>
              <InputCalendar
                inputClassName='letter-xs pointer'
                className='b-rounded-gray letter-xs white-back pointer'
                locale={locale}
                value={resumeDate || newDate.unix()}
                name='resumeDate'
                renderDay={renderDay}
                onViewModeChange={() => false}
                onNavigateBack={(amount, type, curDate) => moment().utc().startOf(type).isBefore(curDate.utc())}
                onChange={val => this.setState({ resumeDate: val })}
                placeholder='Select start date'
              />
            </div>
          </div>
          <div className='w-75 mb-4 mr-auto ml-auto text-left'>
            <span className='req'>Delivery time window:</span>
            <SelectValidation
              containerClassName='pointer position-relative'
              className='styled-select b-rounded-gray '
              selectClassName='letter-xs pointer'
              emptyValue='Select delivery time window'
              list={deliveryItems}
              onChange={changeDeliverySlot}
              value={deliverySlot || slot_id}
              name='deliverySlot'
            />
          </div>
          <div className='pl-2 pr-2 w-100 text-center'>
            <p className='text-center'>You can save or cancel the changes after you press "Ok" button.</p>
          </div>
          <div className='w-75 mr-auto ml-auto mt-2'>
            <div className='row'>
              <div className='col-12'>
                <PaperButton
                  className={'place-order button-regular transition w-50 ml-auto mr-auto'}
                  onClick={() => {
                    this.props.updateModal({ display: false });
                    this.recalculateDates(resumeDate || newDate.unix(), pauseOrder, includesDay);
                  }}
                >
                  Ok
                </PaperButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderModifyModal = acceptTC => {
    const { placeOrder } = this.props;
    return (
      <Fragment>
        <div className='mb-3 mr-auto ml-auto'>
          <div className='row'>
            <CheckBox
              className='m-auto'
              labelClassName='checkbox-container checkbox-box mr-0 pr-0 d-flex align-items-center flex-row-reverse'
              spanClassName='regular green m-0'
              spanClassName2='checkmark mt-1 position-relative d-inline-block mr-2'
              name='acceptTC'
              title='I have read and accept the terms & conditions.'
              value={acceptTC}
              onChange={(name, val) => this.setState({ [name]: !!val })}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <PaperButton
              className={`place-order button-regular transition w-25 ml-auto mr-auto ${acceptTC ? '' : 'disabled'}`}
              onClick={() => {
                if (acceptTC) {
                  this.setState({ modal: '' });
                  //placeOrder();
                  browserHistory.push('/');
                }
              }}
            >
              Save
            </PaperButton>
          </div>
        </div>
      </Fragment>
    );
  };

  renderCancelModal = acceptTC => {
    const { cancelOrder, planParams } = this.props;
    const { payed } = planParams || {};
    return (
      <Fragment>
        <div className='text-center modal-message'>
          <div className='pl-5 pr-5'>
            <p className='text-center'>{'You are about to cancel an order. You can receive a discount coupon ' +
            'for the sum equivalent to the difference between the total order sum and the sum of meals you already received.'}</p>
          </div>
          <div className='w-75 mb-5 mr-auto ml-auto'>
            <div className='row'>
              <CheckBox
                className='m-auto'
                labelClassName='checkbox-container checkbox-box mr-0 d-flex align-items-center flex-row-reverse ml-auto mr-0-i'
                spanClassName='regular green m-0'
                spanClassName2='checkmark mt-1 position-relative d-inline-block mr-2'
                name='acceptTC'
                title='I have read and accept the terms & conditions.'
                value={acceptTC}
                onChange={(name, val) => this.setState({ [name]: !!val })}
              />
            </div>
          </div>
          <div className='row'>
            <div className={`col-6${payed ? ' full-size' : ' offset-3'}`}>
              <PaperButton
                className={`place-order button-regular transition w-c-80 ml-auto ${acceptTC ? '' : 'disabled'}`}
                onClick={() => {
                  if (acceptTC) {
                    this.setState({ modal: 'clear' });
                    cancelOrder(false);
                  }
                }}
              >
                Cancel
              </PaperButton>
            </div>
            {!!payed && <div className='col-6 full-size'>
              <PaperButton
                className={`place-order button-regular transition w-c-80 mr-auto ${acceptTC ? '' : 'disabled'}`}
                onClick={() => {
                  if (acceptTC) {
                    this.setState({ modal: '' });
                    cancelOrder(true);
                  }
                }}
              >
                Cancel and receive coupon
              </PaperButton>
            </div>}
          </div>
        </div>
      </Fragment>
    );
  };

  getModalContent = (type) => {
    let content = null;
    const { acceptTC } = this.state;
    switch (type) {
      case 'place':
        content = this.renderModifyModal(acceptTC);
        break;
      case 'cancel':
        content = this.renderCancelModal(acceptTC);
        break;
      case 'pause':
        content = this.renderPauseModal(acceptTC);
        break;
    }
    return (
      <div className={'order-modify-modal in-modify-page'}>
        {content}
      </div>
    );
  };

  setModal(type) {
    this.modalType = type;
    this.props.setModal({
      message: this.getModalContent(type),
      onClose: () => this.setState({ acceptTC: false, resumeDate: false })
    });
  }

  render() {
    const { xyz, date, showdetails } = this.state;
    const {
      goMenu, excludeWeekDay, period, skipped, originTo, planParams, paused,
      mealPlan, cancelPause, isMob, showOnMobileBlock, switchModType, showSwitcher, curModType
    } = this.props;
    const nextModType = { active: 'future', future: 'active' }[curModType];
    const { modified } = planParams || {};
    /*const week = Array(31).fill(0).map((el, id) => {
      const day = date.clone().add(id, 'day');
      if (day.unix() in mealPlan) {
        const inactive = day.unix() in skipped || day.isBefore(moment().add(48, 'h'));
        return (<Day
          key={id}
          date={day}
          goMenu={goMenu}
          inactive={inactive}
        />);
      }
    });

    console.log(week);*/
    // const needRepButton = !!(period.to.diff(period.from, 'days') >= 7);
    //const hasPrevWeek = date.clone().subtract(1, 'week').endOf('week').isAfter(period.from);
    //const hasNextWeek = date.clone().add(1, 'week').startOf('week').isBefore(period.to);
    const toShow = isMob ? showOnMobileBlock : 'menu';
    return (
      <Fragment>
        {(toShow === 'menu') &&
        <Fragment>
          <div className={`order-block-holder ${xyz ? 'xyz' : 'wrapper xyz-fin'}`}>
            <div className='head-line'>
              {/*<ToolTip
                overlayClassName={'on-dishes'}
                tooltipDirection={'bottom'}
                trigger={['click']}
                tooltipText={<div className=''>
                  <div className='regular-bold'>You can pause the delivery or cancel it.</div>
                  <div className='t-total'><span className='green'>After any action, please click the save button and the changes will take effect.</span></div>
                </div>}
              >
                <h5>
                  <span>?</span>
                  Plan details
                </h5>
              </ToolTip>*/}

              <img src={Promo} className="PromoImage" />
             {/* <PaperButton
                  className='place-order transition m-auto col-5 h-30 PromoButton'
                  onClick={() => this.orderPromo()}
                >
                  Add to Plan
             </PaperButton>*/}
              <p style={{marginTop:12+"px"}}>Please WhatsApp us on <a href='tel:+9710506340653' className='tel'>050-634-0653</a> for us to help you with the menu or for any assistance.</p>
            </div>
            <div className='container'>
              <div className='row top-buttons'>
                {/*<PaperButton
                  className={paused ? 'place-order cancel transition m-auto col-5 h-30 next' : 'place-order transition m-auto col-5 h-30'}
                  onClick={() => paused ? cancelPause() : this.setModal('pause')}
                >
                  {paused ? 'Cancel changes' : 'Change start / pause '}
                </PaperButton>
                <PaperButton
                  className='place-order transition m-auto col-5 h-30'
                  onClick={() => this.setModal('cancel')}
                >
                  Cancel plan
                </PaperButton>*/}
                <p className='sub-line diet-name-edit'>Dish Details</p>
              </div>
            </div>
            <div className="dishDetailsBlock"><p>Click on a dish on the left to view it's details & ingredients.</p></div>
            {showSwitcher && <div className={`bottom-mod-type-switcher right-switcher ${nextModType}`} onClick={() => switchModType(nextModType)}>View my { (nextModType == 'future') ? 'renewed' : 'active' } plan</div>}
          </div>
          <div className='order-buttons'>
            {/*originTo.diff(period.from, 'days') >= 31 &&
            <div className='row weeks-nav'>
              <div className='col-6'>
                <div className={`week-switch ${hasPrevWeek ? '' : 'disabled'}`} onClick={() => hasPrevWeek && this.onChangeDate(-1)}><span>←</span>!Prev week</div>
              </div>
              <div className='col-6 text-right'>
                <div className={`week-switch last ${hasNextWeek ? '' : 'disabled'}`} onClick={() => hasNextWeek && this.onChangeDate(1)}>Next week<span>→</span></div>
              </div>
            </div>
            */}
            {false && <PaperButton
              className='place-order button-regular transition w-90'
              onClick={this.replicate}
            >
              Replicate
            </PaperButton>}

            <div className='row weeks-nav'>
              <div className='col-12'>
                <PaperButton
                  className={`place-order w-100 transition ${modified || paused ? '' : 'disabled'}`}
                  //onClick={() => (modified || paused) && this.submit()}
                  onClick={() => browserHistory.push('/')}
                >
                  Save
                </PaperButton>
              </div>
            </div>
          </div>
        </Fragment>
        }
      </Fragment>
    );
  }
}

Order.propTypes = {
  planParams: PropTypes.object.isRequired,
  date: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  originTo: PropTypes.object.isRequired,
  mealPlan: PropTypes.object.isRequired,
  setMealPlan: PropTypes.func.isRequired,
  setPlanParams: PropTypes.func.isRequired,
  excludeWeekDay: PropTypes.array,
  excludedDates: PropTypes.array,
  dataArray: PropTypes.object.isRequired,
  period: PropTypes.object.isRequired,
  setModal: PropTypes.func,
  updateModal: PropTypes.func,
  onChangeDate: PropTypes.func,
  goMenu: PropTypes.func,
  placeOrder: PropTypes.func.isRequired,
  cancelOrder: PropTypes.func.isRequired,
  pauseOrder: PropTypes.func.isRequired,
  paused: PropTypes.bool.isRequired,
  skipped: PropTypes.object.isRequired,
  onReady: PropTypes.func,
  locale: PropTypes.string.isRequired,
  cancelPause: PropTypes.func.isRequired,
  showOnMobileBlock: PropTypes.any,
  showToggler: PropTypes.any,
  slot_id: PropTypes.any,
  showOnMobileBlockToggler: PropTypes.func,
  bottomButtonClick: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired,
  isMob: PropTypes.any

};

const mapStateToProps = state => ({
  planParams: state.planParams,
  dataArray: state.dataArray,
  mealPlan: state.mealPlan,
  user: state.user.user_id,
  locale: (state.user.language || 'en-US').substr(-2)
});

const bindAction = dispatch => ({
  setMealPlan: plan => dispatch(setMealPlan(plan)),
  setModal: obj => dispatch(setModal(obj)),
  clearModal: obj => dispatch(clearModal(obj)),
  setPlanParams: params => dispatch(setPlanParams(params)),
  setModal: obj => dispatch(setModal(obj)),
  updateModal: obj => dispatch(updateModal(obj))
});

export default socketConnect(connect(mapStateToProps, bindAction, null, { withRef: true })(Order));