import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import noImage from 'assets/no_img.svg';
import DishDescription from 'components/DishDescription';
class Dish extends Component {
    constructor(props) {
        super(props);
    }
    truncateText=(truncated, maxLength)=>{
        if (truncated.length > maxLength) {
            truncated = truncated.substr(0,maxLength) + '...';
        }
        return truncated;
    }
    showDishDetails= (productId) =>{
        const { setModal , dataArray} = this.props;
        const { productsList } =dataArray;
       setModal({ headLine: '', message:
         (<DishDescription
                             product={{ ...productsList[productId], id: productId }}
                             ingredients={dataArray['ingredientList'] || {}}
                             ratings={[]}
                             onClick={() => {
                               // this.hide('showDescription');
                               // this.hideToggler(true);
                             }}
                           /> )
                         });
     }
    render(){
        const { product, typeList, type_id,sideDish } = this.props;
        let fat = product.fat / 1000;
        let protein = product.protein / 1000;
        let carb = product.carb / 1000;
        let calories = product.calories / 1000;
        console.log(sideDish);
        const sideTitle =(sideDish!=undefined && sideDish.length>0)?sideDish.map((childdish)=>{
            if(childdish.title!=undefined){
                calories+=(childdish.calories/1000); 
                protein+=(childdish.protein/1000);
                fat+=(childdish.fat/1000);
                carb+=(childdish.carb/1000);
                return <h2 className="product-title transition">{this.truncateText((childdish.title!=undefined)?childdish.title:'',55)}</h2>;
            }
            
        }):'';
        return <div className="product transition">
                            <div className="img-holder d-card-img">
                                <div className="image_click ">
                                    <img src={product.preview || noImage} />
                                    <h3 className="">{typeList[type_id].title}</h3>
                                </div>
                               
                            </div>
                            <div className='product-info'>
                                <div className="inner d-card-caption ">
                                    <h2 className="product-title transition">{this.truncateText(product!=undefined?(sideTitle?product.title+'+'+sideTitle:product.title):'',55)}</h2>
                                    {/* {sideTitle && <div class="side-title-cont">{sideTitle}</div>} */}
                                    
                                </div>
                                <div className="d-flex discrd-ftr">
                                <div className='ing-info'>
                                        <div className="main-dish">
                                            <div className="mb-2">
                                                <span className="card-kacal"><i><img src="" /></i>Kcal {calories}</span><span>PRO {protein}g,</span><span>CHO {carb}g,</span><span>F {fat}g</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="discrd-ftr-lt">
                                        <button className="btn-label-turq-outline d-v-more-btn" onClick={() => this.showDishDetails(+product.id)}>More Details</button>
                                    </div>
                                </div>
                            </div>
                    </div>;
    }
}
Dish.propTypes = {
    dataArray: PropTypes.object.isRequired,
    socket: PropTypes.object.isRequired,
    checkoutMealPlan: PropTypes.object.isRequired,
    checkoutPlanParams: PropTypes.object.isRequired,
    date: PropTypes.object,
};
export default Dish;