import React, { Component } from 'react';
import SelectInput from 'components/Select';
class SideDishModal extends Component {

	constructor(props) { 
        super(props);
        const { dishId } = this.props;
        this.state = {
            dishId
        };
    }
    onChange = (value) => {
        this.setState({dishId:value});
    }
    render() {
        const { currentType , sideId, changeMealside,sidelist,currentDate } = this.props;
        const { dishId } =this.state;
       
        return (<SelectInput
            labelText='Change Sides'
            list={sidelist}
            value={dishId || {}}
            searchable={false}
            name='sideId'
            onChange={ 
                (name,value) => { this.onChange(value);  changeMealside(+currentDate, +currentType, +value.value,sideId) }
              }
          />);
    }
}
export default SideDishModal;