import React,{ Component, Fragment } from "react";
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import { spin, stop } from '../../store/spinner'; 
import PaperButton from 'react-paper-button';
import { browserHistory } from "react-router";
import InputValidation from 'components/InputValidation';
import SelectValidation from 'components/SelectValidation';
import { setModal, clearModal } from 'store/modal';
import { getArrays } from 'lib';
import { userUpdate } from 'store/user';
class CheckoutThankyou extends Component{
    constructor(props) {
        super(props);
        const { user } = this.props;
        const { homeAddress , workAddress } = user || {street:'', apartments:'', emirate:'', area:'', landmark:'',locationlink:''};
        console.log(homeAddress);
        console.log(workAddress);
        this.state={
          homeAddress:this.isJsonString(homeAddress)?JSON.parse(homeAddress):homeAddress,
          workAddress:this.isJsonString(workAddress)?JSON.parse(workAddress):workAddress,
          errors:[]
        }
    }
    componentDidMount(){
        const { socket } = this.props;
        if(socket.connected){
            this.initializeData();
        }else{
            socket.on('connect', (data) => {
                this.initializeData();
            });
        }
    }
    initializeData =() =>{
        const { socket, dataArray,user } = this.props;
        // console.log(dataArray);
        const types = ['allEmirates', 'areasListFull'].filter(el => !(el in dataArray));
        console.log(types);
        types.length && getArrays(socket, types);
      }
    componentWillMount() {
        this.props.socket.on('profile', this.listener);
    }
    componentWillUnmount() {
        this.props.socket.removeListener('profile', this.listener);
    }
    listener = ({ type, data }) => {
        this.props.stop();
        const {clearModal, setModal} = this.props;
        const { message } = data || {};
        // console.log(type);
        switch (type) {
            case 'saveAddressInfoOk':
              const updateData={homeAddress:this.state.homeAddress,workAddress:this.state.workAddress};
              this.props.updateUser(updateData);
                clearModal();
                setModal({ headLine: 'Delivery Address', message: (
                    <div>
                    <p>{message}</p>
                    <PaperButton onClick={() => {clearModal(); browserHistory.push('/'); }} className='button-small transition continue'>
              <span>Ok</span>
              </PaperButton>
                    </div>)
                });             
            break;
        }
    }
    saveAddressInfo() {
      const { homeAddress, workAddress } = this.state;
      const {street, apartments:apts, emirate, area, landmark, locationlink} = homeAddress;
      const {street:work_street, apartments:work_apts, emirate:work_emirate, area:work_area, landmark:work_landmark, locationlink:work_locationlink} = workAddress;
        const {user} = this.props;
        let isValid= true;
        if(!street){
          isValid=false;
         alert('Please enter a street.');
          return false;
        }
        if(!apts){
          isValid=false;
          alert( 'Please enter a Apartment or Villa.');
          return false;
        }
        if(!emirate){
          isValid=false;
          alert( 'Please select emirate.');
          return false;
        }
        if(!area){
          isValid=false;
          alert('Please select area.');
          return false;
        }
       
        if(locationlink){
            if(!this.validateMapURL(locationlink)){
              isValid=false;
              alert('Invalid Google Maps URL');
              return false;
            }
         }
         if(isValid){
             this.props.spin();
                this.props.socket.emit('profile', {type: 'getForDashboard', data: {
                  'subtype': 'saveAddressInfo', 
                  'default_home':1,
                  'default_work':0,
                  'userId': user.user_id,
                  'street': street,
                  'apts': apts,
                  'area': area,
                  'emirate': emirate,
                  'landmark': landmark,
                  'locationlink': locationlink,
                  'work_street': work_street,
                  'work_apts': work_apts,
                  'work_area': work_area,
                  'work_emirate': work_emirate,
                  'work_landmark': work_landmark,
                  'work_locationlink': work_locationlink,
                } 
            });
         }
       
        // this.setNotification('Delivery Address', 1);
      }
      changeUserAddress = (type,name, value) => 
      {   
        // console.log(this.state[type]);
          this.setState({
            [type]:{ ...this.state[type], [name]: value },
          });
         setTimeout(() => this.address(), 10);
      }
   isJsonString =(str) =>{
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
  validateMapURL = (url) => {
      // Regular expression to match Google Maps URL
      // const regex = /^https?:\/\/(?:www\.)?maps.app.goo\.gl\/(?:q=)(.*?)(?:\&|$)/;
      const regex = /^(https?\:\/\/)?(maps\.app\.goo\.gl|goo\.gl|www\.google\.com)\/.+$/;
      // https://maps.app.goo.gl/eB3jaP8TV2pHBNBJ6
      return regex.test(url);
    };
 address() {
	const {setModal, dataArray} = this.props;
    const { homeAddress, workAddress,errors } = this.state;
    const {street, apartments:apts, emirate, area, landmark, locationlink} = homeAddress;
    const {street:work_street, apartments:work_apts, emirate:work_emirate, area:work_area, landmark:work_landmark, locationlink:work_locationlink} = workAddress;
    const { allEmirates, areasListFull} = dataArray;
    
    var areas = [];
    var work_areas = [];
    var public_areas = [];
    
    if (emirate && typeof(emirate) != 'undefined') {
      areasListFull.map( (el, idx) => {
        
        if (el.emirate_id == emirate) {
          areas[+el.id] = el.name;
        }

        if (el.emirate_id == work_emirate) {
          work_areas[+el.id] = el.name;
        }

      });
    }

	setModal({ headLine: 'Delivery Address', message: (
          <div className="addresses-fields">
            <h3>Main Address</h3>
            <div className="address-edit-div">
                <InputValidation
                  name={'street'}
                  value={street || ''}
                  placeholder='Street'
                  onChange={ (name, value) => this.changeUserAddress('homeAddress','street', value)}
                  errors={errors}
                  maskProps={{
                    disabled:true
                  }}
                />
                <InputValidation
                  name={'apartments'}
                  value={apts || ''}
                  placeholder='Apartment or Villa #'
                  onChange={ (name, value) => this.changeUserAddress('homeAddress','apartments', value)}
                  errors={errors}
                  maskProps={{
                    disabled:true
                  }}
                />
                <SelectValidation
                  name={'language'}
                  value={emirate || null}
                  onChange={(name, value) => this.changeUserAddress('homeAddress','emirate', value)}
                  errors={errors}
                  list={allEmirates}
                  emptyValue={'Select Emirate'}
                  canEmpty
                />
                <SelectValidation
                  name={'language'}
                  value={area || null}
                  onChange={(name, value) => this.changeUserAddress('homeAddress','area', value)}
                  errors={errors}
                  list={areas}
                  emptyValue={'Select Area'}
                  canEmpty
                />
                <InputValidation
                  name={'landmark'}
                  value={landmark || ''}
                  placeholder='Nearest Landmark'
                  onChange={ (name, value) => this.changeUserAddress('homeAddress','landmark', value)}
                  errors={errors}
                />
                <InputValidation
                  name={'locationlink'}
                  value={locationlink || ''}
                  placeholder='Google Map Link'
                  onChange={ (name, value) => this.changeUserAddress('homeAddress','locationlink', value)}
                  errors={errors}
                />
            </div>

            <h3>2nd Address</h3>
            <div className="address-edit-div">
                <InputValidation
                  name={'street'}
                  value={work_street || ''}
                  placeholder='Street'
                  onChange={ (name, value) => this.changeUserAddress('workAddress','street', value)}
                  errors={errors}
                />
                <InputValidation
                  name={'apartments'}
                  value={work_apts || ''}
                  placeholder='Apartment or Villa #'
                  onChange={ (name, value) => this.changeUserAddress('workAddress','apartments', value)}
                  errors={errors}
                />
                <SelectValidation
                  name={'language'}
                  value={work_emirate || null}
                  onChange={(name, value) => this.changeUserAddress('workAddress','emirate', value)}
                  errors={errors}
                  list={allEmirates}
                  emptyValue={'Select Emirate'}
                  canEmpty
                />
                <SelectValidation
                  name={'language'}
                  value={work_area || null}
                  onChange={(name, value) => this.changeUserAddress('workAddress','area', value)}
                  errors={errors}
                  list={work_areas}
                  emptyValue={'Select Area'}
                  canEmpty
                />
                <InputValidation
                  name={'landmark'}
                  value={work_landmark || ''}
                  placeholder='Nearest Landmark'
                  onChange={ (name, value) => this.changeUserAddress('workAddress','landmark', value)}
                  errors={errors}
                />
                <InputValidation
                  name={'locationlink'}
                  value={work_locationlink || ''}
                  placeholder='Google Map Link'
                  onChange={ (name, value) => this.changeUserAddress('workAddress','locationlink', value)}
                  errors={errors}
                />
                <p align="center">
                    <PaperButton className='button-regular next transition'
                     onClick={ (e) => {this.saveAddressInfo();}}
                    >
                        <span>Save</span>
                    </PaperButton>
                </p>
            </div>
          </div>
        )});
	}
    render(){
        const { user } = this.props;
        const { homeAddress } = user;
        const { emirate } = homeAddress;
        return <Fragment>
        <div className="main-holder checkout-process-con wrap-progress-page" >
        <div className="container checkout-process">
                    <div className="row">
                    <div className='col-12'>
                       <div className="thank-container text-center">
                            <h1 style={{textAlign:'center',marginBottom:10,color:'#001f3d',marginTop:100,paddingHorizontal:20,fontSize:24}}>Thank you!</h1>
                            {emirate!=undefined && <p>Please confirm if the delivery address is correct</p>}
                            {emirate==undefined && <PaperButton onClick={e => this.address()} className='button-small transition light-blue-btn'>
                            Please fill the delivery address
              </PaperButton>}
              {emirate!=undefined && <Fragment><PaperButton onClick={() => {  browserHistory.push('/'); }} className='button-small transition continue mb-2'>
              <span>Confirmed</span>
              </PaperButton><PaperButton onClick={e => this.address()} className='button-small transition light-blue-btn'>
              Update Address
              </PaperButton></Fragment>}
                        </div>
                    </div>
                    </div>
        </div>
        </div>
        </Fragment>;
    }
}
CheckoutThankyou.propTypes = {
    dataArray: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    dataArray: state.dataArray,
    user:state.user
});
const bindAction = dispatch => ({
    setModal: obj => dispatch(setModal(obj)),
    spin: () => dispatch(spin()),
    stop: () => dispatch(stop()),
    updateUser: user => dispatch(userUpdate(user)),
    clearModal: obj => dispatch(clearModal(obj))
});
export default socketConnect(connect(mapStateToProps, bindAction)(CheckoutThankyou));