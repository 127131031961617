import React, { Component } from 'react';
import { Link } from 'react-router';

class SupportView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xyz: false
    };
  }

  componentDidMount() {
    setTimeout(() => this.SupportViewRef && this.setState({ xyz: true }), 300);
  }

  render() {
    const { xyz } = this.state;
    return (
      <div ref={el => (this.SupportViewRef = el)} className={`row bg-1-1 main-page m-0 main-holder support-main ${xyz ? 'xyz-fin' : 'xyz'}`}>
        <div className='col-12 main-content without-scroll'>
          <div className='row title-holder'>
            <div className='col-12 title-block main-title'>
              <h1 className='clr-white'>Nutrition support</h1>
              <p className='clr-white'>
                <span className='ar-golr-l' />
                <span className='clr-white'>Tips, articles and discussions</span>
                <span className='ar-golr-r' />
              </p>
            </div>
          </div>
          <div className='row buttons-holder'>
            <div className='col-12 col-md-6 d-flex flex-column justify-content-center align-items-center align-items-md-end'>
              <Link to='/nutrition-support/blog' className='transition button-regular main'>
                {/* <span className='transition icon-entree icons-regular' /> */}
                <span className='title'>To the blog</span>
              </Link>
            </div>
            <div className='col-12 col-md-6 d-flex flex-column justify-content-center align-items-center align-items-md-start'>
              <Link to='/nutrition-support/forum' className='transition button-regular main'>
                {/* <span className='transition icon-formyfriend icons-regular' /> */}
                <span className='title'>To the discussions</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SupportView;
