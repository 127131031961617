import { persistCombineReducers } from 'redux-persist';
import createEncryptor from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/es/storage';
import { reducer as formReducer } from 'redux-form';
import userReducer from './user';
import dataArrayReducer from './dataArray';
import mealPlanReducer from './mealPlan';
import planParamsReducer from './planParams';
import modalReducer from './modal';
import burgerMenuReducer from './burgerMenu';
import customPlansReducer from './customPlans';
import customPlanSidesReducer from './customPlanSides';
import defaultDishesReducer from './defaultDishes';
import settingsReducer from './settings';
import spinnerReducer from './spinner';
import remainDeliveriesReducer from './remainDeliveries';
import customMealPlanReducer from './customMealPlan';
import checkoutPlanParamsReducer from './checkoutPlanParams';
import checkoutMealPlanReducer from './checkoutMealPlan';
import checkoutMealPlanSidesReducer from './checkoutMealPlanSides';
const encryptor = createEncryptor({
  secretKey: 'GU;"7?6$&QS,F,+"mxac+f{`*pycFc*kB(W,\\e66uW"\\XJVjh*KT9Nx8tFG&6_DAp{]G^`?#_`(wqb`*_{~UK}.:+Q.[Ds#(=a,_wQ-*tkSm}\')AV{a!<X#bew%DU.%/'
});

const config = {
  key: 'root',
  transforms: [encryptor],
  blacklist: ['dataArray', 'clientType', 'modal'],
  whitelist: ['mealPlan', 'planParams', 'user', 'customPlans','customPlanSides'],
  storage
};

export const makeRootReducer = (asyncReducers) => {
  return persistCombineReducers(config, {
    mealPlan: mealPlanReducer,
    planParams: planParamsReducer,
    user: userReducer,
    dataArray: dataArrayReducer,
    modal: modalReducer,
    form: formReducer,
    burger: burgerMenuReducer,
    customPlans: customPlansReducer,
    customMealPlan: customMealPlanReducer,
    customPlanSides: customPlanSidesReducer,
    defaultDishes: defaultDishesReducer,
    settings: settingsReducer,
    spinner: spinnerReducer,
    remainDeliveries: remainDeliveriesReducer,
    checkoutPlanParams: checkoutPlanParamsReducer,
    checkoutMealPlan: checkoutMealPlanReducer,
    checkoutMealPlanSides: checkoutMealPlanSidesReducer,
    ...asyncReducers
  });
};

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;

  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
