import React,{ Component, Fragment } from "react";
import PropTypes from 'prop-types';

class Price extends Component{

    constructor(props) {
        super(props);
        this.state={
          isShowBag:this.props.isShowBag|| true
        }
    }
    
    render(){
      const { priceDatas,keyValue, isShowBag } = this.props;
      const { discountPrice,price,discountPer,savedAmount,discountType } =priceDatas || {};
      const CURRENCY='AED';
      const BAG_PRICE=180;
      return priceDatas!=undefined && <div className={"priceCon"}>
      <div className={"flexRow"}>
        {discountPer > 0 && <Fragment>
          <p className={"discountPrice"}>{CURRENCY} {price/100}</p>
          <div className="discountOffCon">
          {price>0 && <p className={"price"}>{CURRENCY} {discountPrice/100}</p>}
          <div className={"discountPerCon"}>
            <p className={"discountPer"}>{discountPer}{discountType=='percent'?'%':''}</p>
            <p className={"discountPerSub"}>{discountType=='percent'?'OFF':''}</p>
          </div>
          </div>
        </Fragment>}
        {discountPer == 0 && price>0 && <p className={"price"}>{CURRENCY} {price/100}</p>}
      </div>
      {savedAmount>0 && <p className={"savePrice"}><span>Saved {CURRENCY} {savedAmount/100}</span> on this order.</p>}
      {isShowBag && <p className={"bagPrice"}><span>+{BAG_PRICE} Refundable bag deposit is required </span><span>on your first order. Not applicable on renewal.</span></p>}
    </div>;
    }
}
Price.propTypes = {
  priceDatas: PropTypes.object.isRequired,
  isShowBag: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool
  ]),
};
Price.defaultProps = {
  priceDatas: {},
  isShowBag:false
};
export default Price;