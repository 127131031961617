import React,{ Component, Fragment } from "react";
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import { getArrays,intersect } from 'lib';
import PropTypes from 'prop-types';
import moment from 'moment';
import { setCheckoutPlanParams, updateCheckoutPlanParams } from '../../store/checkoutPlanParams';
import { setCheckoutMealPlan,updateCheckoutMealPlan } from '../../store/checkoutMealPlan';
import { setCheckoutMealPlanSides,updateCheckoutMealPlanSides } from '../../store/checkoutMealPlanSides';
import { spin, stop } from '../../store/spinner'; 
import Tabs from '../../components/Checkout/Tabs';
import BottomNav from '../../components/Checkout/BottomNav';
import InputCalendar from '../../components/InputCalendar';
import Select from 'components/Select';
import { browserHistory } from "react-router";

class CheckoutStep3 extends Component{

    constructor(props) {
        super(props);
        const { planParams,checkoutPlanParams,dataArray, checkoutMealPlan } = this.props;
        // console.log(planParams);
        const { diet_id,mealTypes,emirate,slot_id,id:checkoutId } = checkoutPlanParams;
        let minVal =moment().add(3,'days').utc().startOf('day');
        if(planParams && planParams.end_date!=undefined &&  minVal.unix()<planParams.end_date){
          minVal= moment.unix(planParams.end_date).add(1,'day');
        }
        let defaultDate = checkoutPlanParams.start_date || minVal.unix();
        if(defaultDate<minVal.unix()){
              defaultDate=minVal.unix();
         }
        const { emirate:emirateObj, emirateSlots} = dataArray;
        const emirateVal = emirateObj?{label:emirateObj[emirate],value:emirate}:'';
        const slotVal =emirateSlots && emirateSlots[emirate]?{label:emirateSlots[emirate].filter(el => !(el.id==slot_id))[0].display_name,value:slot_id}:'';
        const slotList =[]; 
        (emirateSlots && emirate && emirateSlots[emirate]) && emirateSlots[emirate].reduce((obj, key) => {
          slotList.push({label:key.display_name,value:key.id});
        }, []);
        const today = moment().startOf('day');
        const startDate = (checkoutMealPlan)?Object.keys(checkoutMealPlan)[0]:'';
        const date = moment.unix(startDate); 
        const buffered = today.unix()> startDate || date.isBetween(today, today.clone().add(3, 'day'), '[)');
        if(checkoutId && !buffered) browserHistory.push('/checkout/menus');
        if(!diet_id) browserHistory.push('/checkout');
        if(!mealTypes) browserHistory.push('/checkout/meal-types-and-days');
        this.state={
            deliveryDate:defaultDate,
            minDate:minVal.unix(),
            emirate_id:emirate,
            emirate:emirateVal,
            slot_id:slot_id,
            slot:slotVal,
            emirateList:[],
            slotList:slotList,
        }
    }
    componentDidMount(){
        const { socket } = this.props;
        if(socket.connected){
          this.initializeData();
      }else{
          socket.on('connect', (data) => {
              this.initializeData();
          });
      }
    }
    initializeData =() =>{
      const { socket, dataArray,checkoutPlanParams } = this.props;
        const types = ['emirate','emirateSlots','productsList','sidesTypes', 'typeList', 'dietListFull'].filter(el => !(el in dataArray));
        types.length && getArrays(socket, types);
        const { emirate } = dataArray;
        const emirateList = emirate!=undefined && Object.keys(emirate).map((val)=>{
            return {label:emirate[val],value:val};
        });
        this.setState({emirateList});
        const { diet_id,mealTypes } = checkoutPlanParams;
        this.getDefaultDishes(diet_id,mealTypes);
    }
    componentWillMount() {
        this.props.socket.on('mobile_checkout', this.listener);
    }
    componentWillUnmount() {
      this.props.socket.removeListener('mobile_checkout', this.listener);
    }
    listener = ({ type, data, message }) => {
      this.props.stop();
      // console.log(type);
      switch (type) {
        case 'setOk':
          // console.log(data);
          const { setCheckoutPlanParams } = this.props;
          const { setCheckoutMealPlan } = this.props;
          if(data.planParams!=undefined){
            setCheckoutPlanParams(data.planParams);
            setCheckoutMealPlan(data.mealPlan);
          }
          browserHistory.push('/checkout/menus');
        break;
        case 'setErr':
          // console.log(data);
          console.log(message);
        break;
      }
    }
    checkIsAllergiesOrDislike=(productsList,allergies,dislikes,dishid)=>{
        const { ingredients }= productsList?productsList[dishid] || {}:{};
        let is_allergies=false;
        if(ingredients!=undefined&&ingredients.length>0){
            if (allergies.length>0) {
              is_allergies = intersect(ingredients,allergies).length;
            }
            if(dislikes.length>0&&!is_allergies){
              is_allergies = intersect(ingredients,dislikes).length;   
            }
            if(!is_allergies){
              return  dishid;
            }
            return  null;
          }else{
            return  dishid;
          }
      };
      getDefaultDishes = (dietId, types) => { 
        // this.props.spin();
        const { typeList } = this.props.dataArray || {};
        const childTypes= typeList!=undefined && types.map((val)=>{
            const { children } = typeList[+val];
            return (children!=null)?children.id:null;
        }).filter((el)=>{ return el != null; });
        
        this.props.socket.emit('default_dishes', { type: 'get', data: { dietId, types } });
        this.props.socket.emit('custom_plan', { type: 'get', data: { diet_id:dietId, mealTypes:types } });
        this.props.socket.emit('default_dish_sides', { type: 'get', data: { dietId: dietId, types:childTypes } });
        this.props.socket.emit('custom_plan_sides', { type: 'get', data: { diet_id:dietId, mealTypes:childTypes } });
      };
      continuePress = ()=>{
        const { checkoutPlanParams, updateCheckoutPlanParams, defaultDishes,defaultDishSides } = this.props;
        const { deliveryDate,emirate_id, slot_id} = this.state;
        const { days_count,exclude_week_day } = checkoutPlanParams;
        let isValid = true;
        if(deliveryDate){
          let name='date';
          if(deliveryDate!=checkoutPlanParams.date){
             updateCheckoutPlanParams({ [name]: deliveryDate, modified: true});
          }
        }else{
          isValid =false;
          alert(
            'Please select delivery Date.',
          )
          return false;
        }
        if(emirate_id){
          let name='emirate';
          if(emirate_id!=checkoutPlanParams.emirate){
              updateCheckoutPlanParams({ [name]: emirate_id, modified: true});
          }
        }else{
          isValid =false;
          alert(
            'Please select emirate.',
          )
          return false;
        }
        if(slot_id){
          let name='slot_id';
          // if(slot_id!=checkoutPlanParams.slot_id){
             updateCheckoutPlanParams({ [name]: slot_id, modified: true});
          // }
        }else{
          isValid =false;
          alert(
            'Please select delivery slot.',
          )
          return false;
        }
  
        if(isValid){
         this.props.spin();
          // const { typeList } = this.props.dataArray || {};
          // const childTypes= checkoutPlanParams.mealTypes.map((val)=>{
          //     const { children } = typeList[+val];
          //     return (children!=null)?children.id:null;
          // }).filter((el)=>{ return el != null; });
          let checkoutMealPlanData = this.fillPlanDates(deliveryDate,days_count,exclude_week_day);
          let checkoutMealPlan = this.fillDefaults(checkoutMealPlanData,defaultDishes,defaultDishSides);
          // console.log('checkoutMealPlan');
          // console.log(checkoutMealPlan);
          // return false;
          setCheckoutMealPlan(checkoutMealPlan);
          // if(childTypes.length>0){
          //   let unique = [...new Set(checkoutPlanParams.mealTypes.concat(childTypes))];
          //   updateCheckoutPlanParams({ ['mealTypes']: unique, modified: true});
          // }
          updateCheckoutPlanParams({start_date:Object.keys(checkoutMealPlan)[0],end_date:Object.keys(checkoutMealPlan)[(Object.keys(checkoutMealPlan).length)-1] , modified: true});
          // console.log('checkoutMealPlanData');
          // console.log(checkoutMealPlanData);
          // console.log(checkoutPlanParams.mealTypes);
          // console.log(Object.keys(checkoutMealPlan)[0]);
        
          this.savePlan(checkoutMealPlan);
        }
        return false;
      }
      savePlan = (checkoutMealPlan) =>{
        const { checkoutPlanParams,user } =this.props;
        const { priceDatas } = checkoutPlanParams;
        const { slot_id, emirate_id } =this.state;
        // console.log('checkoutPlanParams');
        // console.log(checkoutPlanParams);
        // console.log(checkoutMealPlan);
        let checkDates = Object.keys(checkoutMealPlan);
        let checkOutdata ={...checkoutPlanParams,user_id:user.user_id,full_price:priceDatas.price,discount_price:priceDatas.savedAmount,slot_id:slot_id,emirate:emirate_id,start_date:checkDates[0],end_date:checkDates[(checkDates.length)-1]};
        // console.log(checkOutdata); 
        // console.log(checkoutMealPlan);
        this.props.socket.emit('mobile_checkout', { type: 'set',   data: {
          checkoutPlanParams:checkOutdata,
          detailsData: checkoutMealPlan
        }});
        // props.stop();
     }
     
    getDish = (date, type,existDish,defaultDishes,sideId=false) => {
      const { user, customPlans,checkoutMealPlan:existMealPlan,checkoutMealPlanSides:existMealPlanSides,dataArray,customPlanSides} = this.props;
        const { productsList } = dataArray;
        if(sideId){
          if(existMealPlanSides[+type]!=undefined&&existMealPlanSides[+type][day]!=undefined&&existMealPlanSides[+type][day][sideId]!=undefined){
            return +(existMealPlanSides[+type][day][sideId])||null;
          }
        }else{
            if(existMealPlan!=undefined && existMealPlan[date]!=undefined && existMealPlan[date][type]!=undefined && existMealPlan[date][type]!=0){
                return +existMealPlan[date][type];
            }
        }
         
          const day = moment.unix(date).date() - 1; // TODO: check logic
          const sameDayDish = Object.values(existDish).map(Number);
          const plan = (sideId)?(customPlanSides.find(el => +el.mealType_id === +type && +el.side_type_id == +sideId ) || {}):(customPlans.find(el => +el.mealType_id === +type) || {});
          let dish=+(defaultDishes[+type] || {})[day] || null;
          if(sideId){
            if(defaultDishes[+type]!=undefined&&defaultDishes[+type][day]!=undefined&&defaultDishes[+type][day][sideId]!=undefined){
              dish=+(defaultDishes[+type][day][sideId])||null;
            }
          }
          /* Start Disable the dislike/allergies dish */
            let prodKeys = (plan.data || {})[day] || [];
    
            const {allergies,dislikes,dishdislikes,eliminate_groups}=user;
    
            let is_allergies=this.checkIsAllergiesOrDislike(productsList,allergies,dislikes,dish);
    
            if(eliminate_groups.length>0 && is_allergies){
            
              const { group,spicy } =productsList[dish] || {};
              // is_allergies=(eliminate_groups.indexOf(group)>-1)?null:dish;
              is_allergies=(intersect(group,eliminate_groups).length>0)?null:dish;
              if(is_allergies){
                is_allergies=(eliminate_groups.indexOf(14)>-1 && spicy==1)?null:dish; // Spicy Group and dish set as spicy
              }
             
            }
    
            if(dishdislikes.length>0 && is_allergies){
              is_allergies=(dishdislikes.indexOf(+dish)>-1)?null:dish;
            }
            
            if(sameDayDish.length>0 && is_allergies){
              is_allergies=(sameDayDish.indexOf(+dish)>-1)?null:dish;
            }
           
            if(is_allergies!=null){
              return +dish;
            }else{
              prodKeys=prodKeys.filter(function (dishId) {
                  const { group,spicy } = productsList[dishId] || {};
                  let isEliminate = true;
                  if(eliminate_groups.length>0){ 
                    // isEliminate = !(eliminate_groups.indexOf(group)>-1); 
                       isEliminate = !intersect(group,eliminate_groups).length;   
                    if(isEliminate){
                       isEliminate=!(eliminate_groups.indexOf(14)>-1 && spicy==1); // Spicy Group and dish set as spicy
                    }
                  }
                  if(dishdislikes.length>0 && isEliminate){
                    isEliminate = !(dishdislikes.indexOf(+dishId)>-1); 
                  }
                  return +isEliminate;
              });
              
              var validDish = prodKeys.find((dishId, idx) => {
                if(dishId!=dish){
                  let is_allergies=this.checkIsAllergiesOrDislike(productsList,allergies,dislikes,dishId);
                  if(sameDayDish.length>0 && is_allergies){
                    is_allergies=(sameDayDish.indexOf(+dishId)>-1)?null:+dishId;
                  }
                  if(is_allergies!=null||(prodKeys.length-1)==idx){
                    return +dishId;
                  }
                }
              });
              return (validDish != undefined )?+validDish:+dish; // Set default dish,If the all dish are dislike/allergies
            }
           /* End Disable the dislike/allergies dish */
    
        }
      fillDefaults = (checkoutMealPlan,defaultDishes,defaultDishSides) => {
        const { checkoutPlanParams, dataArray} = this.props;
        const { sidesTypes, typeList,dietListFull } = dataArray;
        const { mealTypes: types,diet_id } = checkoutPlanParams || {};
        var newmealType=types;
        const { sides_id  } = dietListFull[diet_id] || null;
        return Object.keys(checkoutMealPlan).reduce((acc, date) => {
          const dayData = newmealType.reduce((acc, type) => {
            const { parent_id, title} = typeList[type];
               if(parent_id!=0){
                const sideData = sides_id!=null && Object.keys(sidesTypes).filter(function (sideId) {
                  return (sides_id.indexOf(sideId)>-1);
              }).reduce((acc2, sideId) => {
                        return {...acc2, [+sideId]:this.getDish(date, type,acc2,defaultDishSides,sideId) };
                  }, {});

                return { ...acc, [+type]:sideData };
               }else{
                return { ...acc, [+type]: this.getDish(date, type,acc,defaultDishes) };
               }
            
          }, {});
          // console.log(dayData);
          return { ...acc, [+date]: dayData };
        }, {});
      }
    fillPlanDates = (startDate, count, excludedDays) => {
        startDate =startDate;
        excludedDays =(excludedDays)?excludedDays:[];
        const includedDays = Array(7).fill(0).map((el, key) => key).filter(el => !excludedDays.includes(String(el)));
        let dates = {};
        if(count>0 && startDate){
            for (let i = 0; i < count; i++) {
              startDate = moment.unix(this.getinitialDate(includedDays,(i==0)?startDate:startDate+3600*24)).unix();
              dates[startDate] = {};
            }
        }
        return dates;
      };
    getValidDate = (initdate,excludedDays) =>{
            if(excludedDays && excludedDays.length>0 && excludedDays.length<7){
                if(excludedDays.includes(initdate.day())||excludedDays.includes(String(initdate.day()))){
                  return this.getValidDate(initdate.add(1,'day'),excludedDays);
                }else{
                  return initdate;
                }
            }else{
              return initdate;
            }
      } 
     getinitialDate=(includedDays,startDate)=>{
        var initialDate = moment.unix(startDate);
        if(includedDays.includes(initialDate.weekday())){
          return startDate;
        }else{
           return this.getinitialDate(includedDays,startDate+3600*24);
        }
      };
    updateEmirateSolts = () =>{
        const { dataArray } = this.props;
        const { emirateSlots } = dataArray;
        const { emirate_id } = this.state;
        const slotList =[];
         (emirateSlots && emirate_id && emirateSlots[emirate_id]) && emirateSlots[emirate_id].reduce((obj, key) => {
            slotList.push({label:key.display_name,value:key.id});
        }, []);
        // console.log(slotList);
        this.setState({slotList,slot:[],slot_id:''});
    }
    updateValidateDate =()=>{
        const { checkoutPlanParams } = this.props;
        const { exclude_week_day } = checkoutPlanParams;
        const { deliveryDate } = this.state;
        var reDate = this.getValidDate(moment.unix(deliveryDate),exclude_week_day).unix();
        this.setState({deliveryDate:reDate});
    }
    renderDates(){
        const { deliveryDate, emirate,slot, emirateList,slotList, minDate } = this.state;
        console.log('deliveryDate');
        console.log(deliveryDate);
        const { checkoutPlanParams,dataArray } = this.props;
        const { exclude_week_day } = checkoutPlanParams;
        const { emirate:emirateArray,emirateSlots } = dataArray;
        const emirateList1 =( emirateArray!=undefined && emirateList.length==0)?Object.keys(emirateArray).map((val)=>{
            return {label:emirateArray[val],value:val};
        }):emirateList;
        const slotList1 =(slotList.length==0)?(emirateSlots && emirate && emirateSlots[emirate]) && emirateSlots[emirate].reduce((obj, key) => {
          return {label:key.display_name,value:key.id};
      }, []):slotList;
         
        let newDate = moment().add(3,'days');
        const renderDay = (props, currentDate) => {
            if (!currentDate.isBetween(newDate, newDate.clone().add(3, 'months'), null, '[]') || exclude_week_day.includes(String(currentDate.days()))) {
              props.className = `${props.className} rdtDisabled`;
              delete props.onChange;
              delete props.onClick;
            } else if (currentDate.isSame(deliveryDate || newDate)) {
              props.className += ' selected';
            }
            return <td {...props}>{currentDate.date()}</td>;
          };
          const today = moment();
        !today.isUTC() && today.utc();
        today.startOf('day');
        
        
        return <div className="step-form-group">
        <h5>Choose start date & delivery time window</h5>
            <div className="popup-select-area step-form-input">
                <label className="food-prefs-header">Start Date</label>
                <div className="popup-dash-calendar-fix">
            <InputCalendar
                inputClassName='letter-xs pointer'
                locale='en'
                value={deliveryDate || newDate.unix()}
                dateFormat={'YYYY-MM-DD'}
                name='deliveryDate'
                // before={minDate}
               renderDay={renderDay}
                onViewModeChange={() => false}
               // onNavigateBack={(amount, type, curDate) => moment().utc().startOf(type).isBefore(curDate.utc())}
                onChange={val => this.setState({ deliveryDate: val },()=>{this.updateValidateDate()})}
                placeholder='Select start date'
              />
              </div>
            </div>
            <div className="popup-select-area step-form-input">
                <label className="food-prefs-header">Emirates</label>
                <div className={'className'}>
                 <Select
                            multiple={false}
                            isgrouped={true}
                        placeholder='Select Emirates'
                        list={emirateList1 || []}
                        value={emirate || ''}
                        isSearchable={true}
                        name='selectedDay'
                        onChange={ (name, val) => {
                            // console.log(val);
                        // this.changeDislike('dislikes', val);
                        this.setState({
                            emirate_id: val.value,
                            emirate:val
                        },()=>{ this.updateEmirateSolts();});
                        }}
                        />
                    </div>
            </div>
            <div className="popup-select-area step-form-input">
                <label className="food-prefs-header">Delivery  Time</label>
                <div className={'className'}>
                 <Select
                            multiple={false}
                            isgrouped={true}
                        placeholder='Select Delivery  Time'
                        list={slotList1|| []}
                        value={slot || ''}
                        isSearchable={true}
                        name='selectedDay'
                        onChange={ (name, val) => {
                            // console.log(val);
                        // this.changeDislike('dislikes', val);
                        this.setState({
                        slot_id: parseInt(val.value),
                        slot: val
                        });
                        }}
                        />
                    </div>
            </div>
            <p>*Please note the delivery will happen any time within the selected  delivery time window.</p>
        </div>
    }
    render() {
        const { checkoutPlanParams } = this.props;
        const { priceDatas } = checkoutPlanParams;
        return <Fragment>
            <div className="main-holder checkout-process-con wrap-progress-page" >
            <div className="container checkout-process">
                        <div className="row">
                        <div className='col-12'>
                           <Tabs />
                           <div className="inner-content">
                            <h2 className="mobile-header">Start Date & Delivery Time</h2>
                           {this.renderDates()}
                           </div>
                           <BottomNav priceDatas={priceDatas} isShowBag={true} continueValid={this.continuePress} />
                        </div>
                    </div>
                    </div>
              </div>
        </Fragment>
    }
}
CheckoutStep3.propTypes = {
    checkoutPlanParams: PropTypes.object.isRequired,
    checkoutMealPlan: PropTypes.object.isRequired,
    setCheckoutMealPlan: PropTypes.func.isRequired,
    setCheckoutMealPlanSides: PropTypes.func.isRequired,
    setCheckoutPlanParams: PropTypes.func.isRequired,
    dataArray: PropTypes.object.isRequired
};
  
const mapStateToProps = state => ({
    checkoutPlanParams: state.checkoutPlanParams,
    dataArray: state.dataArray,
    user:state.user,
    planParams: state.planParams,
    checkoutMealPlan: state.checkoutMealPlan,
    checkoutMealPlanSides: state.checkoutMealPlanSides,
    defaultDishes: state.defaultDishes,
    defaultDishSides: state.defaultDishSides,
    customPlans: state.customPlans,
    customPlanSides: state.customPlanSides
});

const bindAction = dispatch => ({
    setModal: obj => dispatch(setModal(obj)),
    setCheckoutMealPlan: plan => dispatch(setCheckoutMealPlan(plan)),
    setCheckoutMealPlanSides: plan => dispatch(setCheckoutMealPlanSides(plan)),
    setCheckoutPlanParams: obj => dispatch(setCheckoutPlanParams(obj)),
    updateCheckoutPlanParams: obj => dispatch(updateCheckoutPlanParams(obj)),
    spin: () => dispatch(spin()),
    stop: () => dispatch(stop()),
    updateCheckoutMealPlan: (date,obj) => dispatch(updateCheckoutMealPlan(date,obj)),
    updateCheckoutMealPlanSides: (date,obj) => dispatch(updateCheckoutMealPlanSides(date,obj)),
    setModal: obj => dispatch(setModal(obj))
});
  
export default socketConnect(connect(mapStateToProps, bindAction)(CheckoutStep3));