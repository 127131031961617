import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import 'rc-time-picker/assets/index.css';
import './style.css';
import moment from 'moment';
import ToolTip from '../ToolTip';
import CalendarIcon from 'assets/calender.png';
const cbStyle = {
  display: 'block',
  position: 'absolute',
  opacity: 0
};

class InputCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCalendar: false,
      value: '',
      calendarValue: ''
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({
        value: nextProps.value ? moment.unix(nextProps.value).utc().locale(this.props.locale).format(nextProps.dateFormat?nextProps.dateFormat:'L') : '',
        calendarValue: nextProps.value ? moment.unix(nextProps.value) : moment().utc().locale(this.props.locale).startOf('day')
      });
    }
  }

 /* showIt = () => {
    this.setState({
      showCalendar: true,
    })
  }*/

  componentWillMount() {
    const { value, locale,dateFormat } = this.props;
    moment().locale(locale);
    this.setState({
      value: value ? moment.unix(value).locale(locale).utc().format(dateFormat?dateFormat:'L') : '',
      calendarValue: value ? moment.unix(value).utc() : moment().utc().startOf('day')
    });
    if (this.props.showCalendarForSure) {
      this.setState({ showCalendar: true });
    }
  }

  updateDate = e => {
    if (!this.props.showCalendarForSure) {
      this.setState({ value: e.locale(this.props.locale).format(this.props.dateFormat?this.props.dateFormat:'L'), showCalendar: false });
      this.props.toggle(false);
    }
    this.props.onChange(e.locale(this.props.locale).startOf('day').unix());
    this.InputCalendarRef.blur();
  };

  toggleCalendar = () => {
    const { showCalendar } = this.state;
    const { disable } = this.props;
    if (!disable || showCalendar) {
      if (showCalendar) {
        this.InputCalendarRef.blur();
      } else {
        this.props.toggle(showCalendar);
        this.setState({ showCalendar: !showCalendar });
      }
    }
  }; 

  onBlur = () => {
    if (!this.onCal) {
      this.props.toggle(false);
      this.setState({ showCalendar: false });
    }
  };

  render() {
    const {
      inputClassName, iClassName, className, name, errors, classError, placeholder, classNameCalendar, tooltipProps,
      locale, before, viewMode, disable, renderDay, fullYear, renderMonth, renderYear, defaultValue, showCalendarForSure, onViewModeChange, onNavigateForward, onNavigateBack,dateFormat
    } = this.props;
    const { showCalendar, value } = this.state;
    return (
      <Fragment>
        <div className={`${showCalendar ? 'calendar-selected' : 'calendar-disabled'}`} />
        <div
          className={`${className} ${!showCalendarForSure ? 'absolute-cal' : ''} ${name in errors ? classError : ''}`}
          tabIndex='0'
          onBlur={this.onBlur}
          onClick={this.toggleCalendar}
          ref={el => (this.InputCalendarRef = el)}
        >
          {!showCalendarForSure &&
          <ToolTip {...tooltipProps}>
            <div>
              <input type='checkbox' style={cbStyle} />
              <input
                type='text'
                className={`${inputClassName} ${showCalendar ? 'selected' : disable ? 'disabled' : ''}`}
                name={name}
                onClick={this.toggleCalendar}
                value={value}
                placeholder={placeholder}
              />
              {iClassName && <i className={iClassName} />}
              <img src={CalendarIcon} />
            </div>
          </ToolTip>
          }
          {name in errors &&
            <span className={classError}>{errors[name]}</span>
          }
          {showCalendar &&
            <div onMouseEnter={() => (this.onCal = true)} onMouseLeave={() => (this.onCal = false)}>
              <Datetime
                locale={locale}
                className={classNameCalendar}
                onChange={this.updateDate}
                viewMode={viewMode}
                input={false}
                value={value}
                timeFormat={false}
                dateFormat={(dateFormat!=undefined)?dateFormat:true}
                utc
                isValidDate={current => {
                  return fullYear
                    ? moment().diff(current, 'years') >= -18
                    : before
                      ? current.isBefore(Datetime.moment().startOf('day'))
                      : current.isAfter(Datetime.moment().startOf('day'));
                }}
                renderDay={renderDay}
                renderMonth={renderMonth}
                renderYear={renderYear}
                defaultValue={defaultValue}
                onViewModeChange={onViewModeChange}
                onNavigateBack={onNavigateBack}
                onNavigateForward={onNavigateForward}
              />
            </div>
          }
        </div>
      </Fragment>
    );
  }
}

InputCalendar.propTypes = {
  before: PropTypes.bool,
  fullYear: PropTypes.bool,
  placeholder: PropTypes.string,
  viewMode: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
  className: PropTypes.string,
  classNameCalendar: PropTypes.string,
  classError: PropTypes.string,
  inputClassName: PropTypes.string,
  iClassName: PropTypes.string,
  name: PropTypes.string,
  locale: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  toggle: PropTypes.func,
  disable: PropTypes.bool,
  errors: PropTypes.object,
  renderDay: PropTypes.func,
  onNavigateForward: PropTypes.func,
  onNavigateBack: PropTypes.func,
  renderMonth: PropTypes.func,
  renderYear: PropTypes.func,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  showCalendarForSure: PropTypes.bool,
  onViewModeChange: PropTypes.func,
  tooltipProps: PropTypes.object
};

InputCalendar.defaultProps = {
  before: false,
  fullYear: false,
  disable: false,
  viewMode: 'days',
  placeholder: '',
  className: 'search-profile-main-cal form-input calendar-input pointer',
  classNameCalendar: '',
  inputClassName: ' standart-input b-rounded-blue shadowed letter-xs white-back pointer',
  iClassName: '',
  classError: 'has-error',
  name: '',
  onChange: () => {},
  onViewModeChange: () => true,
  onNavigateBack: () => true,
  onNavigateForward: () => true,
  toggle: () => {},
  errors: {},
  renderDay: (props, currentDate, selectedDate) => {
    return <td {...props}>{ currentDate.date() }</td>;
  },
  renderMonth: (props, month, year, selectedDate) => {
    return <td {...props}>{ moment().month(month).format('MMM') }</td>;
  },
  renderYear: (props, year, selectedDate) => {
    return <td {...props}>{ year }</td>;
  },
  defaultValue: moment().startOf('day'),
  showCalendarForSure: false,
  tooltipProps: {
    tooltipText: '',
    tooltipDirection: 'top',
    trigger: []
  }
};

export default InputCalendar;
