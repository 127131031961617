import React,{ Component, Fragment } from "react";
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import { getArrays } from 'lib';
import PropTypes from 'prop-types';
import moment from 'moment';
import { setCheckoutPlanParams, updateCheckoutPlanParams } from '../../store/checkoutPlanParams';
import { setCheckoutMealPlan,updateCheckoutMealPlan } from '../../store/checkoutMealPlan';
import { userUpdate } from '../../store/user';
import { spin, stop } from '../../store/spinner'; 
import Tabs from '../../components/Checkout/Tabs';
import BottomNav from '../../components/Checkout/BottomNav';
import Select from 'components/Select';
import { browserHistory } from "react-router";
import CheckIcon from 'assets/checkDiet.png';

class CheckoutStep1 extends Component{

    constructor(props) {
        super(props);
        const { user } = this.props;
        // console.log(socket);
        const { diet_id,id:checkoutId } = this.props.checkoutPlanParams || {};
        const { eliminate_groups } = user;
        if(checkoutId) browserHistory.push('/checkout/menus');
        console.debug('Construtor');
        this.state={
            diet:diet_id || null,
            ingredientListData:[],
            dislikes:[],
            eliminate_groups
        }
    }
    componentDidMount(){
        const { socket } = this.props;
        if(socket.connected){
            this.initializeData();
        }else{
            socket.on('connect', (data) => {
                this.initializeData();
            });
        } 
    }
    initializeData =() =>{
        console.debug('Init Data');
        const { socket, dataArray,user } = this.props;
        const types = ['productsList','ingredientList','dietMobileList','dishGroups','planDayRange','daysRangeMob','mealtypeMobCombinations','typeList'].filter(el => !(el in dataArray));
        console.debug(types);
        if(types.length>0){
            this.props.spin();
            getArrays(socket, types);
        }
        const { id:checkoutId } = this.props.checkoutPlanParams || {};
        if(checkoutId){ browserHistory.push('/checkout/menus');}else{
            this.props.spin(); 
            this.getPending(user.user_id);
        }
    }
    setInitialState(){
        const { dataArray,user } = this.props;
        const { ingredientList,dishGroups } =dataArray;
        const { dislikes,eliminate_groups } = user;
        var dislikeList = (dislikes && ingredientList!=undefined)?this.formatSelectOption(dislikes,ingredientList):[];
        var eliminateList = (eliminate_groups && dishGroups!=undefined)?this.formatSelectOption(eliminate_groups,dishGroups):[];
        this.setState({'dislikes':dislikeList,'eliminate_groups':eliminateList});
    }
    componentWillMount() {
      this.props.socket.on('mobile_checkout', this.listener);
      this.props.socket.on('profile', this.listener);
    }
    componentWillUnmount() {
      this.props.socket.removeListener('mobile_checkout', this.listener);
      this.props.socket.removeListener('profile', this.listener);
    }
    getPending = user_id => this.props.socket.emit('mobile_checkout', { type: 'getPending', data: { user_id } });
    saveProfile = (data,action) =>{
        const { user } = this.props;
        this.props.socket.emit('profile', { type: 'getForDashboard', data: { userId: user.user_id, subtype: 'saveProfDataMobile', theaction: action, value: data,deviceType:'web'} });
    }
    listener = ({ type, data, message }) => {
        this.props.stop(); 
        switch (type) {
           case 'saveProfDataMobileOk':
            const { value,action } = data || {};
            var updateData = {};
            updateData[action] = value;
            this.props.updateUser(updateData);
           break;
           case 'getPendingOk':
            this.setInitialState();
              const { setCheckoutPlanParams, setCheckoutMealPlan } = this.props;
              if(data && data.mealPlan !=undefined ){
                setCheckoutPlanParams(data.planParams);
                setCheckoutMealPlan(data.mealPlan);
                browserHistory.push('/checkout/menus');
              }
            break;
           default:
           break;
        }
    }
    setDietType(diet){
        // console.log(dietId);
        this.setState({diet:diet.id});
    }
    renderDietTypes (){
        const { dataArray } = this.props;
        const { diet } = this.state;
        const { dietMobileList, dietListByGroup } = dataArray;
        const list= dietMobileList && dietMobileList.map((value,index)=>{
            const handle = value.name.replace(/\s+/g, '-').toLowerCase();
                return <li className={`diet-list ${(diet==value.id) ? 'diet-active' : ''}`} onClick={() => this.setDietType(value,handle)}>
                    <img src={require('assets/'+handle+'-ic.png')} />
                    <span>{value.name}</span>
                    {(diet==value.id) && <img src={CheckIcon} className="checkIcon" />}
                </li>
        });
        return <div className="step-form-group">
            <h5>Diet Type</h5>
            <div className="step-form-input">
            <label>Choose Type</label>
            <ul className="checkout-diet-list">
            {list}
        </ul></div></div>;
    }
    sortByName(list){
        return list!=undefined && Object.keys(list).sort((a,b)=>{
            const nameA = list[a].toUpperCase(); // ignore upper and lowercase
            const nameB = list[b].toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
       });
    }
    formatSelectOption(list,dataList){
        let ingredientListData = [];
        list!=undefined && list && list.map((val)=>{
            ingredientListData.push({label: dataList[val], value: parseInt(val)});
        });
        return ingredientListData;
    }
    renderPreference(){
        const { user, dataArray } = this.props;
        const { dislikes, eliminate_groups } = this.state;
        const { ingredientList, dishGroups } = dataArray;
        let ingredientList1 =this.sortByName(ingredientList);
        let ingredientListData = this.formatSelectOption(ingredientList1,ingredientList);
        let dishGroupList = dishGroups!=undefined ?this.formatSelectOption(Object.keys(dishGroups),dishGroups):[];
        var dislikeList = (user.dislikes && ingredientList!=undefined && dislikes.length==0)?this.formatSelectOption(user.dislikes,ingredientList):dislikes;
        var eliminateList = (user.eliminate_groups && dishGroups!=undefined && eliminate_groups.length==0)?this.formatSelectOption(user.eliminate_groups,dishGroups):eliminate_groups;
        return <Fragment><div className="step-form-group">
        <h5>Preferences</h5>
        <div className="popup-select-area step-form-input">
        <label className="food-prefs-header">Eliminate</label>
        <div className={'className'}>
        <Select
          multiple={true}
          isgrouped={true}
          placeholder='Change Eliminate'
          list={dishGroupList}
          value={eliminateList || []}
          isSearchable={true}
          name='eliminate_groups'
          // onChange={e => this.changeDislike('dislikes',e)}
          onChange={ (name, val) => {
            // console.log(val);
           // this.changeDislike('dislikes', val);
           this.setState({
           'eliminate_groups': val
          });
          this.saveProfile(val.map((value)=>{ return parseInt(value.value);}),'eliminate_groups');
          }}
        />
      </div>
      </div>
        <div className="popup-select-area step-form-input">
        <label className="food-prefs-header">Dislikes</label>
        <div className={'className'}>
        <Select
          multiple={true}
          isgrouped={true}
          placeholder='Change Dislikes'
          list={ingredientListData}
          value={dislikeList || []}
          isSearchable={true}
          name='dislikes'
          // onChange={e => this.changeDislike('dislikes',e)}
          onChange={ (name, val) => {
            // console.log(val);
           // this.changeDislike('dislikes', val);
           this.setState({
           'dislikes': val
          });
        //   console.log(val.map((value)=>{ return parseInt(value.value);}));
          this.saveProfile(val.map((value)=>{ return parseInt(value.value);}),'dislikes');
          }}
        />
      </div>
      </div>
      <p className="note">*Note: Certain ingredients cannot be avoided and if more than three, please consult.</p>
      </div></Fragment>;
    }
    validate = ()=>{
        const { diet } = this.state;
        const { updateCheckoutPlanParams, setCheckoutPlanParams,checkoutPlanParams, dataArray } = this.props;
        const { dietMobileList } = dataArray;
        if(!diet){
            alert('Please select diet type to continue');
            return false;
        }else{
            let mealTypeList = dietMobileList.filter((value)=>(value.id ==diet  && value.mealtypes)); 
            // console.log('mealTypeList');
            // console.log(mealTypeList);
            if(mealTypeList[0].mealtypes.length<=0){
                alert("Please select different diet type. Currently, don't have meals");
                return false;
            }else{
                checkoutPlanParams && updateCheckoutPlanParams({ 'diet_id': diet, modified: true });
                !checkoutPlanParams && setCheckoutPlanParams({ 'diet_id': diet, modified: true });
            }
            
        }
        return true;
    }
    render() {
        
        // console.log(dietMobileList);
        return <Fragment>
            <div className="main-holder checkout-process-con wrap-progress-page" >
                        <div className="container checkout-process step1">
                        <div className="row">
                        <div className='col-12'>
                           <Tabs />
                           <div className="inner-content">
                            <h2 className="mobile-header">Diet Type & Preference</h2>
                                {this.renderDietTypes()}
                                {this.renderPreference()}
                           </div>
                           <BottomNav continueValid={this.validate} />
                        </div>
                        </div>
                    </div>
              </div>
        </Fragment>
    }
}
CheckoutStep1.propTypes = {
    checkoutPlanParams: PropTypes.object.isRequired,
    checkoutMealPlan: PropTypes.object.isRequired,
    setCheckoutMealPlan: PropTypes.func.isRequired,
    setCheckoutPlanParams: PropTypes.func.isRequired,
    dataArray: PropTypes.object.isRequired
};
  
const mapStateToProps = state => ({
    checkoutPlanParams: state.checkoutPlanParams,
    dataArray: state.dataArray,
    user:state.user,
    checkoutMealPlan: state.checkoutMealPlan
});

const bindAction = dispatch => ({
    setModal: obj => dispatch(setModal(obj)),
    setCheckoutMealPlan: plan => dispatch(setCheckoutMealPlan(plan)),
    setCheckoutPlanParams: obj => dispatch(setCheckoutPlanParams(obj)),
    updateCheckoutPlanParams: obj => dispatch(updateCheckoutPlanParams(obj)),
    spin: () => dispatch(spin()),
    stop: () => dispatch(stop()),
    updateCheckoutMealPlan: (date,obj) => dispatch(updateCheckoutMealPlan(date,obj)),
    updateUser: user => dispatch(userUpdate(user)),
    setModal: obj => dispatch(setModal(obj))
});
  
export default socketConnect(connect(mapStateToProps, bindAction)(CheckoutStep1));