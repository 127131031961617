
import React,{ Component, Fragment } from "react";
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import { getArrays } from 'lib';
import PropTypes from 'prop-types';
import Tabs from '../../components/Checkout/Tabs';
import { spin, stop } from '../../store/spinner'; 
import Notpaidnotification from '../../components/Checkout/Notpaidnotification';
import RadioInputNew from '../../components/RadioInput/indexnew';
import Price from '../../components/Checkout/Price';
import RightArrow from 'assets/btn-right-arrow.png';
import { setCheckoutPlanParams,updateCheckoutPlanParams } from '../../store/checkoutPlanParams';
import { setCheckoutMealPlan,updateCheckoutMealPlan } from '../../store/checkoutMealPlan';
import { browserHistory } from "react-router";
class CheckoutSummary extends Component{

    constructor(props) {
        super(props);
        const { checkoutPlanParams } = this.props;
        const { id:checkoutId } = checkoutPlanParams;
        if(!checkoutId || checkoutPlanParams==undefined) browserHistory.push('/checkout');
        let bgPrice=(checkoutPlanParams.bag_price!=undefined)?(checkoutPlanParams.bag_price || 180):180;
        this.state={
            cutlery:checkoutPlanParams.cutlery || 0,
            bag_price:bgPrice,
            paymentMethod:'telr'
        }
    }
    renderContent = (title,content) =>{
        return <div className="summary-label-con">
        <p className='summary-label'>{title}</p>
        <h6>{content}</h6>
     </div>
      }
    componentDidMount(){
     
      const { socket } = this.props;
      if(socket.connected){
          this.initializeData();
      }else{
          socket.on('connect', (data) => {
              this.initializeData();
          });
      } 
    }
    initializeData =() =>{
      const { socket, dataArray,user } = this.props;
      // console.log(dataArray);
      const types = ['dietList','typeList','productsList'].filter(el => !(el in dataArray));
      types.length && getArrays(socket, types);
      const { id:checkoutId } = this.props.checkoutPlanParams || {};
      if(!checkoutId){ 
          this.props.spin(); 
          this.getPending(user.user_id);
      }
    }
    componentWillMount() {
      this.props.socket.on('mobile_checkout', this.listener);
    }
    componentWillUnmount() {
    this.props.socket.removeListener('mobile_checkout', this.listener);
    }
    listener = ({ type, data, message }) => {
      this.props.stop();
      // console.log(type);
      switch (type) {
        case 'updateOk':
            // console.log(data);
            // navigation.navigate('BuildMenuPayment');
            const { paymentMethod } = this.state;
            if(paymentMethod=='not_paid'){
              browserHistory.push('/checkout/success/paylater');
            }else{
              this.createTelrSession(data.checkoutId);
            }
          break;
          case 'getPendingOk':
              const { setCheckoutPlanParams, setCheckoutMealPlan } = this.props;
              console.log(data);
              if(data && data.mealPlan !=undefined ){
                setCheckoutPlanParams(data.planParams);
                setCheckoutMealPlan(data.mealPlan);
                this.setState({cutlery:data.planParams.cutlery,bag_price:data.planParams.bag_price});
              }
            break;
        case 'createTelrSessionOk':
          console.log(data.response);
        //   const { setCheckoutPlanParams } = this.props;
        //   const { setCheckoutMealPlan } = this.props;
        //   if(data.planParams!=undefined){
        //     setCheckoutPlanParams(data.planParams);
        //     setCheckoutMealPlan(data.mealPlan);
        //   }
        if(data.response!=undefined){
            if(data.response.order!=undefined){
                // browserHistory.push(data.response.order.url);
                window.location.href =data.response.order.url;
                return null;
            }
            
        }
          
        break;
        case 'setErr':
          // console.log(data);
          console.log(message);
        break;
      }
    }
    
    savePlan = (checkoutId) =>{
      
        const { updateCheckoutPlanParams,checkoutPlanParams,checkoutMealPlan } = this.props;
        const { cutlery,bag_price } = this.state;
        updateCheckoutPlanParams({cutlery:cutlery,bag_price:bag_price});
        let checkOutdata ={cutlery:cutlery,bag_price:bag_price};
        // console.log('checkOutdata');
        // console.log(bag_price);
        // console.log(checkOutdata);
        // console.log(checkoutPlanParams.days_count);
        // console.log(Object.keys(checkoutMealPlan).length);
        if(checkoutPlanParams.days_count==Object.keys(checkoutMealPlan).length){
            this.props.spin();
            this.props.socket.emit('mobile_checkout', { type: 'update',   data: {
                  details:checkOutdata,
                  checkoutId:checkoutId
                }});
        }else{
          alert('Meal plan days and generate meals count are mismatched. Please check days count or Delete and Start Over meal plan.');
        }
       
     }
     getPending = user_id => this.props.socket.emit('mobile_checkout', { type: 'getPending', data: { user_id } });
    createTelrSession = (checkoutId) =>{
        this.props.spin();
        this.props.socket.emit('mobile_checkout', { type: 'createTelrSession', data: { checkoutId } });
    }
    onChange = (name,val)=>{
        this.setState({[name]:val});
    }
    renderPriceData(bag_price){
      const { checkoutPlanParams } = this.props;
      const { priceDatas } = checkoutPlanParams;
      return <Price priceDatas={priceDatas} isShowBag={bag_price>0} />;
    }
    render(){
        const cutleryDatas =[{'title':'Yes',value:1},{'title':'No',value:0}];
        // const paymentOption =[{'title':'Payment via online',value:'telr'},{'title':'Pay Later - Payment via online payment link to be sent later.',value:'not_paid'}];
        const paymentOption =[{'title':'Payment via online',value:'telr'}];
        const bagPriceOption = [
            { title: '180 Aed Refundable Bag Deposit', value: 180,message:''  },
            { title: 'Renewal: already paid bag deposit', value: 0,message:'*Bag Deposit is Mandatory. If not paid, it be charged before start of the plan.' }
          ];
        const { checkoutPlanParams,dataArray } = this.props;
        // console.log(checkoutPlanParams);
        const { typeList,dietList } = dataArray; 
        const { days_count,diet_id,mealTypes,priceDatas,id:checkoutId } = checkoutPlanParams;
        const { bag_price,cutlery,paymentMethod } = this.state;
        const mealTypeList = (mealTypes && typeList) ? mealTypes.map((val)=>{
          if(typeList[val].parent_id==0){
            return typeList[val].title;
          }
          }).filter(n => n):[];

        return <Fragment>
        <div className="main-holder checkout-process-con wrap-progress-page" >
        <Notpaidnotification />
        <div className="container checkout-process summary">
                    <div className="row">
                    <div className='col-12'>
                       <Tabs />
                       <div class="step-form-group">
                       <h5>Checkout</h5> </div>
                       <div className="summary-container">
                      
                        <div className="summary-con-left">
                            {this.renderContent('Meal Plan',(dietList?dietList[diet_id]:''))}
                            {this.renderContent('Meals',mealTypeList.join(' + '))}
                            {this.renderContent('Days',days_count+" Days")}
                            <div className="cutlery-con">
                              <RadioInputNew name='cutlery' label={'Cutlery'} datas={cutleryDatas} value={cutlery} onChange={this.onChange} />
                            </div>
                            <RadioInputNew name='bag_price' label={'Eco-friendly cooler bag'} datas={bagPriceOption} isHorizontal={true} value={bag_price} onChange={this.onChange} />
                       </div>
                       <div className="summary-con-right">
                            <div className="summary-con-right-sticky">
                                <h2>Payment & Offer Details</h2>
                                {this.renderPriceData(bag_price)}
                                <div className="">
                                   <RadioInputNew name='paymentMethod' label={'Payment Method'} datas={paymentOption} isHorizontal={true} value={paymentMethod} onChange={this.onChange} />
                                </div>
                               <div className="btn-con"><div className='button-small transition continue' onClick={() => { this.savePlan(checkoutId); }}><span>Proceed to Pay</span>
                                    <span className="icon"><img src={RightArrow} /></span></div></div>
                            </div>
                       </div>
                       </div>
                      
                    </div>
                </div>
                </div>
          </div>
    </Fragment>;
    }
}

CheckoutSummary.propTypes = {
    checkoutPlanParams: PropTypes.object.isRequired,
    checkoutMealPlan: PropTypes.object.isRequired,
    setCheckoutMealPlan: PropTypes.func.isRequired,
    setCheckoutPlanParams: PropTypes.func.isRequired,
    dataArray: PropTypes.object.isRequired
};
  
const mapStateToProps = state => ({
    checkoutPlanParams: state.checkoutPlanParams,
    dataArray: state.dataArray,
    user:state.user,
    checkoutMealPlan: state.checkoutMealPlan,
    customPlans: state.customPlans
});

const bindAction = dispatch => ({
    setModal: obj => dispatch(setModal(obj)),
    setCheckoutMealPlan: plan => dispatch(setCheckoutMealPlan(plan)),
    setCheckoutPlanParams: obj => dispatch(setCheckoutPlanParams(obj)),
    updateCheckoutPlanParams: obj => dispatch(updateCheckoutPlanParams(obj)),
    spin: () => dispatch(spin()),
    stop: () => dispatch(stop()),
    updateCheckoutMealPlan: (date,obj) => dispatch(updateCheckoutMealPlan(date,obj)),
    clearModal: obj => dispatch(clearModal(obj))
});
  
export default socketConnect(connect(mapStateToProps, bindAction)(CheckoutSummary));