import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
// import 'react-select/dist/react-select.css';

class MultiSelectValidation extends Component {
  render() {
    const { value, onChange, placeholder, className, classError, name, errors, list } = this.props;
    const options = Object.keys(list).map(value => {
      return ({ value, label: list[value] });
    });
    return (
      <div className={`${className} ${name in errors ? classError : ''}`}>
        <Select
          options={options}
          isMulti
          hideSelectedOptions
          closeMenuOnSelect
          placeholder={placeholder}
          value={value}
          onChange={val => onChange(name, val.map(el => el.value))}
        />
        {name in errors && <span className={classError}>{errors[name]}</span>}
      </div>
    );
  }
}

MultiSelectValidation.propTypes = {
  placeholder: PropTypes.string,
  list: PropTypes.object,
  value: PropTypes.array,
  className: PropTypes.string,
  classError: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  errors: PropTypes.object
};

MultiSelectValidation.defaultProps = {
  placeholder: '',
  list: {},
  className: 'form-input',
  classError: 'has-error letter-xs red-color',
  value: [],
  name: '',
  onChange: () => {},
  errors: {}
};

export default MultiSelectValidation;
