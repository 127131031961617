import React,{ Component, Fragment } from "react";
import { browserHistory } from 'react-router';
import CheckIcon from 'assets/check.png';
class Tabs extends Component{

    constructor(props) {
        super(props);
    }

    renderTabList(){
        const { pathname  } = browserHistory.getCurrentLocation();
        let validStep = [];
        validStep.push(pathname);
        switch(pathname){
            case '/checkout/meal-types-and-days':
                validStep.push('/checkout');
            break;
            case '/checkout/delivery-dates':
                validStep.push('/checkout');
                validStep.push('/checkout/meal-types-and-days');
            break;
            case '/checkout/menus':
                validStep.push('/checkout');
                validStep.push('/checkout/meal-types-and-days');
                validStep.push('/checkout/delivery-dates');
            break;
            case '/checkout/summary':
                validStep.push('/checkout');
                validStep.push('/checkout/meal-types-and-days');
                validStep.push('/checkout/delivery-dates');
                validStep.push('/checkout/menus');
            break;
        }
        const TabList =[
            {'label':'Diet Type & Preference','sublabel':'Step 1','handle':'/checkout'},
            {'label':'Meals','sublabel':'Step 2','handle':'/checkout/meal-types-and-days'},
            {'label':'Start Date & Delivery Time','sublabel':'Step 3','handle':'/checkout/delivery-dates'},
            {'label':'Menu','sublabel':'Step 4','handle':'/checkout/menus'}
        ];
        const renderText = (value,path) =>{
            if(validStep.includes(value.handle) && path!=value.handle) return <div onClick={()=>{ browserHistory.push(value.handle);}} className="step-list">
                    <div className="step-list-top"><div className="step-indicator"><img src={CheckIcon} /></div>
                    <div className="step-sublabel">{value.sublabel}</div></div>
                    <div className="step-label">{value.label}</div>
                </div>;
            
            return <div className="step-list">
                 <div className="step-list-top">{path==value.handle&&<div className="step-indicator"><img src={CheckIcon} /></div>}
                 {path!=value.handle&&<div className="step-indicator empty"><img src={CheckIcon} /></div>}
                    <div className="step-sublabel">{value.sublabel}</div></div>
                    <div className="step-label">{value.label}</div>
            </div>;
        }
        const list = TabList.map((value,index)=>{
             return <li className={`step-tab-list ${(pathname==value.handle || validStep.includes(value.handle)) ? 'step-tab-active' : ''}`}>
                    {renderText(value,pathname)}
                    {value.label !='Menu' && <div className="lineIndicator">________</div>}
             </li>;
        })
        return <ul className="step-tabs">
            {list}
        </ul>;
    }

    render() {
        return <Fragment>
            {this.renderTabList()}
        </Fragment>;
    }
}
export default Tabs;