import React, { Component } from 'react';

class Refunds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xyz: false
    };
  }

  componentDidMount() {
    this.TermsRef && setTimeout(() => this.setState({ xyz: true }), 10);
  }

  render() {
    const { xyz } = this.state;
    return (
      <div ref={el => (this.TermsRef = el)} className={`row main-holder flex-column justify-content-center align-items-center ${xyz ? 'xyz-fin' : 'xyz'}`}>
        <div id="terms-page">
          
          <h1>Refunds & Cancellation Policy</h1>
            <div className="terms-content">
            
<p style={{textAlign:"right"}}>Lose Weight is a brand operating and licensed under Basiligo Restaurant LLC - Sole Proprietorship. For legal purposes, all terms & conditions to Basiligo.</p>
<p>Refunds will be made onto the original mode of payment and will be processed within 10 to 45 days depends on the issuing bank of the credit card. Should there be a request to cancel meal plans; it will take effect in 3 business days. Client can transfer the remaining days to a third party or as an online credit to basiligo.ae online orders.</p>

<ul>
  <li>Cancellation refund for meal plans is only under extenuating circumstance and upon approval of Basiligo Management. A two (2) weeks processing time with twenty percent (20%) cancellation fee will apply on the meal plan retail price with all discounts forfeited. Credit card charges on any product purchased incurred by Basiligo Restaurant will be deducted from the refunded amount.</li>
  <li>Cancellation of al-a-carte delivery orders will result in no refund as the food is prepared as soon as the order is received.</li>
  <li>Cancellation of catering orders must be completed prior to 24 hours of the event. If the client cancels under 24 hours, Basiligo will charge half the total quotation.</li>
  <li>In the event of a global pandemic, Basiligo will not refund but you have the option to pause your plan valid up to one year to resume. </li>
</ul>

            </div>
        </div>
      </div>
    );
  }
}

export default Refunds;
