import React,{ useState, useEffect } from "react";
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import { spin, stop } from '../../store/spinner';
import { getArrays,intersect } from '../../lib';
import { setCheckoutPlanParams } from '../../store/checkoutPlanParams';
import { setCheckoutMealPlan,updateCheckoutMealPlan,deleteCheckoutMealPlan } from '../../store/checkoutMealPlan';
import { setPlanParams } from '../../store/planParams';
import { setMealPlan } from '../../store/mealPlan';
import moment from 'moment';
import { setModal, clearModal } from '../../store/modal';
import { browserHistory } from "react-router";
import PaperButton from 'react-paper-button';

const RenewPlan = (props) => {
    const { params, user,dataArray, checkoutPlanParams, checkoutMealPlan, setCheckoutPlanParams, setCheckoutMealPlan, planParams , mealPlan, defaultDishes, setPlanParams, setMealPlan } = props;
    const { action } = params;
    const [ customDefaultDish , setCustomDefaultDish ] = useState();
    const {id:checkout_id} = checkoutPlanParams;
    const getPending = user_id => props.socket.emit('mobile_checkout', { type: 'getPending', data: { user_id } });
    const getPlanInfo = orderId => { props.socket.emit('profile', { type: 'getForDashboard', data: { orderId: orderId, userId: user.user_id, 'subtype': 'getMealplan'  } }); };
    const getDefaultDishes = (dietId, types) => {
      if(dietId!=undefined && types != undefined){
        const { typeList } = props.dataArray;
        const childTypes= types.map((val)=>{
          const { children } = typeList[+val];
          return (children!=null)?children.id:null;
      }).filter((el)=>{ return el != null; });
        props.spin();
        props.socket.emit('default_dishes', { type: 'get', data: { dietId, types } });
        props.socket.emit('custom_plan', { type: 'get', data: { diet_id:dietId, mealTypes:types } });
        props.socket.emit('default_dish_sides', { type: 'get', data: { dietId: dietId, types:childTypes } });
        props.socket.emit('custom_plan_sides', { type: 'get', data: { diet_id:dietId, mealTypes:childTypes } });
      }
     
    };
    const checkIsAllergiesOrDislike=(productsList,allergies,dislikes,dishid)=>{
      const { ingredients }= productsList?productsList[dishid] || {}:{};
      let is_allergies=false;
      if(ingredients!=undefined&&ingredients.length>0){
          if (allergies.length>0) {
            is_allergies = intersect(ingredients,allergies).length;
          }
          if(dislikes.length>0&&!is_allergies){
            is_allergies = intersect(ingredients,dislikes).length;   
          }
          if(!is_allergies){
            return  dishid;
          }
          return  null;
        }else{
          return  dishid;
        }
    };
    const fillDefaults = (checkoutMealPlan,defaultDishes) => {
      const { user, customPlans} = props;
      const { productsList } = dataArray;
      const { mealTypes: types } = checkoutPlanParams || {};
      var newmealType=types;
      //console.log(customDefaultDish);
      const getDish = (date, type , existDish) => {
        const day = moment.unix(date).date() - 1; // TODO: check logic
        if(customDefaultDish!=undefined && customDefaultDish[day]!=undefined && customDefaultDish[day][type]!=undefined){
              return +customDefaultDish[day][type];
        }
        const sameDayDish = Object.values(existDish);
        // console.log(sameDayDish);
        const plan = customPlans.find(el => +el.mealType_id === +type) || {};
        let dish=+(defaultDishes[+type] || {})[day] || null;
        /* Start Disable the dislike/allergies dish */
          let prodKeys = (plan.data || {})[day] || [];
  
          const {allergies,dislikes,dishdislikes,eliminate_groups}=user;
  
          let is_allergies=checkIsAllergiesOrDislike(productsList,allergies,dislikes,dish);
  
          if(eliminate_groups.length>0 && is_allergies){
          
            const { group,spicy } =productsList[dish] || {};
            // is_allergies=(eliminate_groups.indexOf(group)>-1)?null:dish;
            is_allergies=(intersect(group,eliminate_groups).length>0)?null:dish;
            if(is_allergies){
              is_allergies=(eliminate_groups.indexOf(14)>-1 && spicy==1)?null:dish; // Spicy Group and dish set as spicy
            }
           
          }
  
          if(dishdislikes.length>0 && is_allergies){
            is_allergies=(dishdislikes.indexOf(dish)>-1)?null:dish;
          }
          if(sameDayDish.length>0 && is_allergies){
            is_allergies=(sameDayDish.indexOf(dish)>-1)?null:dish;
          }
          if(is_allergies!=null){
            return +dish;
          }else{
            prodKeys=prodKeys && prodKeys.filter(function (dishId) {
                const { group,spicy } = productsList[dishId] || {};
                let isEliminate = true;
                if(eliminate_groups.length>0){ 
                     isEliminate = !intersect(group,eliminate_groups).length;   
                  if(isEliminate){
                     isEliminate=!(eliminate_groups.indexOf(14)>-1 && spicy==1); // Spicy Group and dish set as spicy
                  }
                }
                if(dishdislikes.length>0 && isEliminate){
                  isEliminate = !(dishdislikes.indexOf(+dishId)>-1); 
                }
                return +isEliminate;
            });
            
            var validDish = prodKeys.find((dishId, idx) => {
              if(dishId!=dish){
                let is_allergies=checkIsAllergiesOrDislike(productsList,allergies,dislikes,dishId);
                if(sameDayDish.length>0 && is_allergies){
                  is_allergies=(sameDayDish.indexOf(dishId)>-1)?null:dishId;
                }
                if(is_allergies!=null||(prodKeys.length-1)==idx){
                  return +dishId;
                }
              }
            });
            return (validDish != undefined )?validDish:+dish; // Set default dish,If the all dish are dislike/allergies
          }
         /* End Disable the dislike/allergies dish */
  
      };
  
      return checkoutMealPlan && newmealType && Object.keys(checkoutMealPlan).reduce((acc, date) => { 
        const dayData = newmealType.reduce((acc, type) => {
          return { ...acc, [+type]: getDish(date, type,acc) };
        }, {});
        // console.log(dayData);
        return { ...acc, [+date]: dayData };
      }, {});
    }
    const  fillPlanDates = (startDate, count, excludedDays) => {
      // startDate =moment.utc(startDate).startOf('day').unix();
      const includedDays = getIncludeDays(excludedDays); 
      let dates = {};
      if(count>0 && startDate){
          for (let i = 0; i < count; i++) {
            startDate = moment.unix(getinitialDate(includedDays,(i==0)?startDate:startDate+3600*24)).unix();
            dates[startDate] = {};
          }
      }
      return dates;
    };
    const  getinitialDate=(includedDays,startDate)=>{
      var initialDate = moment.unix(startDate);
      if(includedDays.includes(initialDate.weekday())){
        return startDate;
      }else{
         return getinitialDate(includedDays,startDate+3600*24);
      }
    };
    const getIncludeDays = (excludedDays) =>{
      excludedDays =(excludedDays)?excludedDays:[];
      return Array(7).fill(0).map((el, key) => key).filter(el => !excludedDays.includes(String(el)));
    }
    const setExistMealPlanMenu = () =>{
        if(action=='samemenu' && mealPlan){
          const cdefaultDish = Object.keys(mealPlan).reduce((acc, date) => {
            const day = moment.unix(date).date() - 1; // TODO: check logic
            const dayData = Object.keys(mealPlan[date]).reduce((acc, type) => {
              return { ...acc, [+type]: mealPlan[date][type] };
            }, {});
            // console.log(dayData);
            return { ...acc, [+day]: dayData };
          }, {})
          setCustomDefaultDish(cdefaultDish);
      }
    }
    const setCheckOutFromPlan = (plandetails)=>{
      // const { typeList } = this.dataArray;
      // let plandetails = planParams;
      setExistMealPlanMenu();
    //  console.log('plandetails');
    //  console.log(plandetails);
      if(plandetails.id!=undefined){
          delete plandetails.id;
          delete plandetails.checkout_id;
          delete plandetails.status;
          delete plandetails.payment_method_id;
          delete plandetails.payment_data;
          delete plandetails.detailedPrices;
          const includedDays = getIncludeDays(plandetails['exclude_week_day']);
          const startDate = getinitialDate(includedDays,plandetails['end_date']+3600*24);
          // console.log(startDate);
        //   const childTypes= plandetails['mealType'].map((val)=>{
        //     const { children } = typeList[+val];
        //     return (children!=null)?children.id:null;
        // }).filter((el)=>{ return el != null; });
          setCheckoutPlanParams({...plandetails,mealTypes:plandetails['mealType'].map(val=>parseInt(val)),date:moment.unix(startDate).format('YYYY-MM-DD'),isRenew:true,diet_id:plandetails.diet,exclude_week_day:plandetails.exclude_week_day.map(val=>String(val))});
          let checkoutMealPlanData = fillPlanDates(startDate,plandetails['days_count'],plandetails['exclude_week_day']);
          // console.log('checkoutMealPlanData');
          // console.log(checkoutMealPlanData);
          if(checkoutMealPlanData){
            console.log(defaultDishes);
            let checkoutMealPlan = fillDefaults(checkoutMealPlanData,defaultDishes);
            setCheckoutMealPlan(checkoutMealPlan);
            console.log(checkoutMealPlan);
            if(checkoutMealPlan != undefined && Object.keys(checkoutMealPlan).length>0){
               if(checkoutPlanParams.id!=undefined){
                browserHistory.push('/checkout');
              }
              
            }
          }
         
      }
    }
    useEffect(() => {
      if(checkout_id) browserHistory.push('/checkout/menus');
        const listener = ({ type, data, message }) => {
          props.stop();          
        //   console.log(type);
          switch (type) { 
            case 'getMealplanOk':
              // console.log(data);
              if(data.planParams.userId==user.user_id){
                setPlanParams(data.planParams);
                setMealPlan(data.mealPlan);
                getDefaultDishes(data.planParams.diet, data.planParams.mealType);
                // setISetParam(1);
                setCheckOutFromPlan(data.planParams);
              }
            break;
            case 'getPendingOk':
              // setIsSkip(true);
              // console.log('datas');
              // console.log(data);
              if(data.planParams!=undefined){
                const includedDays = getIncludeDays(data.planParams['exclude_week_day']);
                const startDate = getinitialDate(includedDays,data.planParams['start_date']);
                setCheckoutPlanParams({...data.planParams,mealTypes:data.planParams['mealTypes'].map(val=>String(val)),date:moment.unix(startDate).format('YYYY-MM-DD')});
                setCheckoutMealPlan(data.mealPlan);
              }else{
                if(planParams.id!=undefined){
                  setCheckOutFromPlan(planParams);
                }else{
                  props.spin();
                  getPlanInfo();
                }
              }
            break;
            default:
             console.log(data);
             console.log(message);
            break;
          }
        };
        props.socket.on('mobile_checkout', listener);
        props.socket.on('profile', listener);
        /* Load the default dish plan exist */
        // console.log(planParams);
        if(planParams.id!=undefined){
          getDefaultDishes(planParams.diet, planParams.mealType);
        }
        const types = ['dietMobileList','typeList','planDayRange','daysRangeMob','mealtypeMobCombinations','productsList','ingredientList'].filter(el => !(el in dataArray));
        if(types.length){ 
          props.spin();
        }
        types.length && getArrays(props.socket, types);
        props.spin();
        getPending(user.user_id);
        return () => {
          props.socket.removeListener('mobile_checkout', listener);
          props.socket.removeListener('profile', listener);
        }
    }, []);
    // let planDates = (checkoutMealPlan)?Object.keys(checkoutMealPlan):[];
    // let dateList=[];
    // if(planDates.length>0){
    //   const startDate = moment.unix(planDates[0]);
    //   const endDate = moment.unix(planDates[planDates.length-1]);
    //   if(startDate && endDate){
    //       while(startDate <= endDate) {
    //         dateList.push(startDate.unix().toString());
    //         startDate.add(1, 'days');
    //       }
    //   }
    // }
   return (
    <div className="main-holder checkout-process-con wrap-progress-page" >
        <div className="container checkout-process">
      {!checkout_id &&<div style={{display:'flex',alignItems:'center',flexDirection:'column',paddingTop:'20px'}}>
        <h2 style={{fontSize:28,textTransform:'capitalize',marginBottom:15,textAlign:'center'}}>Renew Your Meal Plan</h2>
        <PaperButton onClick={()=>{ browserHistory.push('/checkout');}} className={'button-small transition continue'} >
            <span>Yes, continue</span>
        </PaperButton> 
      </div>}
    </div> 
    </div> 
  );
};
const actions = dispatch => ({
  setCheckoutPlanParams: obj => dispatch(setCheckoutPlanParams(obj)),
  setPlanParams: obj => dispatch(setPlanParams(obj)),
  setMealPlan: obj => dispatch(setMealPlan(obj)),
  setModal: obj => dispatch(setModal(obj)),
  clearModal: () => dispatch(clearModal()),
  spin: () => dispatch(spin()),
  stop: () => dispatch(stop()),
  setCheckoutMealPlan: obj => dispatch(setCheckoutMealPlan(obj)),
  updateCheckoutMealPlan: (date,obj) => dispatch(updateCheckoutMealPlan(date,obj)),
  deleteCheckoutMealPlan: (date) => dispatch(deleteCheckoutMealPlan(date))
});
const props = state => ({
  dataArray: state.dataArray,
  user: state.user,
  checkoutPlanParams: state.checkoutPlanParams,
  checkoutMealPlan: state.checkoutMealPlan,
  planParams: state.planParams,
  mealPlan: state.mealPlan,
  defaultDishes: state.defaultDishes,
  customPlans: state.customPlans
});
export default socketConnect(connect(props, actions)(RenewPlan));