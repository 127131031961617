import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import { getDataArray } from 'store/dataArray';
import moment from 'moment/moment';
import { Link, browserHistory } from 'react-router';
import Masonry from 'react-masonry-component';
import Script from 'react-load-script';
import PaperButton from 'react-paper-button';
import ModalWrapper from 'components/ModalWrapper';

class Forum extends Component {
  constructor(props) {
    super(props);
    const page = 1;
    const limit = 20;
    const { forumCategories } = this.props;
    const selectedCategory = Object.keys(forumCategories).reduce((acc, cur) => cur < acc ? +cur : acc, Infinity);
    this.state = {
      threads: false,
      selectedCategory: selectedCategory < Infinity ? selectedCategory : false,
      tab: 'all',
      page,
      limit,
      xyz: false,
      modal: false,
      modalCallback: () => {}
    };
  }

  getThreads(offset, limit, selectedCategory, tab) {
    const { userId } = this.props;
    const curTab = tab || this.state.tab;
    const author = curTab === 'self' && userId ? userId : null;
    this.props.socket.emit('forum', {
      type: 'list',
      data: {
        offset,
        limit,
        filters: {
          category_id: selectedCategory,
          user_id: author
        }
      }
    });
  }

  listener = action => {
    if (this.ForumRef) {
      switch (action.type) {
        case 'listOk':
          const { threads: newThreads, page: newPage } = action.data;
          const { threads: oldThreads, page: oldPage } = this.state;
          const threads = newPage !== oldPage ? { ...oldThreads, ...newThreads } : newThreads;
          this.setState({ threads, page: newPage });
          window.DISQUSWIDGETS && window.DISQUSWIDGETS.getCount({ reset: true });
          break;
        case 'delOk':
          const { limit, selectedCategory } = this.state;
          this.getThreads(0, limit, selectedCategory);
          this.setState({ modal: false, modalCallback: () => {} });
          break;
        case 'listErr':
          console.error(action.data.message);
          break;
      }
    }
  };

  componentWillMount() {
    this.props.socket.on('forum', this.listener);
    const { selectedCategory, threads, page, limit } = this.state;
    if (!threads && selectedCategory) {
      this.getThreads((page - 1) * limit, limit, selectedCategory);
    }
  }

  componentWillUnmount() {
    this.props.socket.removeListener('forum', this.listener);
  }

  componentDidMount() {
    if (this.ForumRef) {
      const { forumCategories, socket } = this.props;
      if (socket && !Object.keys(forumCategories).length) {
        getDataArray({ keys: ['forumCategories'], socket });
      }
      if (forumCategories && Object.keys(forumCategories).length && !this.state.selectedCategory) {
        const selectedCategory = Object.keys(forumCategories).reduce((acc, cur) => cur < acc ? +cur : acc, Infinity);
        this.setState({ selectedCategory });
      }
      setTimeout(() => this.setState({ xyz: true }), 300);
      window.DISQUSWIDGETS && window.DISQUSWIDGETS.getCount({ reset: true });
    }
  }

  componentWillReceiveProps(next) {
    if (this.ForumRef && Object.keys(next.forumCategories).length && !this.state.selectedCategory) {
      const selectedCategory = Object.keys(next.forumCategories).reduce((acc, cur) => cur < acc ? +cur : acc, Infinity);
      if (!Object.keys(this.state.threads).length) {
        const { offset, limit } = this.state;
        this.getThreads(offset, limit, selectedCategory);
      }
      this.setState({ selectedCategory });
    }
  }

  onScrollHandler = e => {
    const item = this.beacon;
    const t = e.target;
    if (item) {
      const height = t.scrollHeight - t.clientHeight - item.clientHeight;
      if (t.scrollTop > height) {
        const { page, limit, selectedCategory } = this.state;
        this.getThreads(page * limit, limit, selectedCategory);
        this.beacon = null;
      }
    }
  };

  scriptLoaded = () => {
    console.log('disqus counter loaded');
  };

  selectThreads = tab => {
    const { selectedCategory: cat, tab: oldTab, limit } = this.state;
    if (this.ForumRef && tab !== oldTab) {
      this.getThreads(0, limit, cat, tab);
      this.setState({ tab, page: 1 });
    }
  };

  renderPlaceholder = () => {
    return (
      <article className='article'>
        <div className='word-content'>
          <h2 className='article-title'>There are no discussions in this category</h2>
          <span>You can add a new discussion</span>
        </div>
      </article>
    );
  };

  renderDeleteModal = callback => {
    return (
      <div className='text-center modal-message'>
        <div className='row modal-header-styled'>
          <div className='col-12 title-block'>
            <p>
              <span className='ar-golr-l' />
              <span className='m-title'>Delete thread</span>
              <span className='ar-golr-r' />
            </p>
          </div>
        </div>
        <div className='pl-5 pr-5'>
          <p className='text-center'>{'Are you sure?'}</p>
        </div>
        <div className='row'>
          <div className='col-6'>
            <PaperButton
              className='place-order button-regular transition w-c-80 ml-auto'
              onClick={callback}
            >
              Yes
            </PaperButton>
          </div>
          <div className='col-6'>
            <PaperButton
              className='place-order button-regular transition w-c-80 mr-auto'
              onClick={() => this.setState({ modal: false })}
            >
              No
            </PaperButton>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { xyz, threads, selectedCategory: cat, offset, limit, page, tab, modal, modalCallback } = this.state;
    const { forumCategories, token, userId, settings } = this.props;
    const changeCat = selectedCategory => {
      if (selectedCategory !== cat) {
        this.getThreads(offset, limit, selectedCategory);
        this.setState({ selectedCategory });
        // document.querySelector('body').scrollIntoView();
      }
    };
    const categoriesMenu = forumCategories && Object.keys(forumCategories).map(id => (
      <div className={`col-12 category ${+cat === +id ? 'active' : ''}`} key={id} onClick={() => changeCat(+id)}>
        <div className='row'>
          <div className='col-12'>
            <p className='category-title'>{forumCategories[id]}</p>
          </div>
        </div>
      </div>
    ));
    const counterUrl = settings.getValue('DISQUS_COUNTER_URL');
    const items = threads ? Object.keys(threads).sort((a, b) => +b - +a).map((threadId, idx) => {
      const editThread = e => {
        e.stopPropagation();
        e.preventDefault();
        browserHistory.push(`/nutrition-support/forum/edit/${threadId}`);
        return false;
      };
      const deleteThread = e => {
        e.stopPropagation();
        e.preventDefault();
        const modalCallback = () => this.props.socket.emit('forum', {
          type: 'del',
          data: { threadId }
        });
        this.setState({ modal: true, modalCallback });
        return false;
      };
      const thread = threads[threadId];
      const { created_at, title, user_id } = thread;
      const url = `/nutrition-support/forum/thread/${threadId}`;
      return (
        <article className='article' key={threadId} ref={el => {
          if (idx + 1 === +limit * page) {
            this.beacon = el;
          }
        }}>
          <Link to={url}>
            <div className='word-content threat'>
              {(token && +userId === +user_id) && <div className='control-buttons-holder'>
                <span className='edit' onClick={editThread}>&#x270E;</span>
                <span className='delete' onClick={deleteThread}>&#x2716;</span>
              </div>}
              <p className='article-date'>{moment.unix(created_at).format('MMMM DD, YYYY')}</p>
              <h1 className='article-title'>{title}</h1>
              <span className={'disqus-comment-count'} data-disqus-url={`https://healthyme.loseweight.ae${url}`} />
              <span className={'disqus-comment-count'} data-disqus-identifier={`${created_at}_${threadId}_${user_id}`} />
            </div>
          </Link>
        </article>
      );
    }) : [];
    return (
      <div className={`main-holder d-flex flex-column ${xyz ? 'xyz-fin' : 'xyz'}`} ref={el => (this.ForumRef = el)}>
        <div className='container-fluid bg-gray-blog height-papa blog-page custom-scrollbar vertical-scrollbar' onScroll={this.onScrollHandler}>
          <div className='row title-holder blog-heading '>
            <div className='col-12  d-flex flex-column justify-content-center title-block blog'>
              <h1>Discussions</h1>
              <p>
                <span className='ar-golr-l' />
                <span>Nutrition support</span>
                <span className='ar-golr-r' />
              </p>
            </div>
          </div>
          <div className='row support-holder discussions-inner'>
            <div className='col-12'>
              <div className='row all-my'>
                <div className='blog-filters'>
                  <div className='back-holder'>
                    <Link to='/nutrition-support' className='back-link'>
                      <span className='back'>&larr;</span>
                    </Link>
                  </div>
                </div>
                <div className={`col-6 category ${tab === 'all' ? 'active' : ''}`} onClick={() => this.selectThreads('all')}>
                  <div className='row'>
                    <div className='col-12'>
                      <p className='category-title text-center'>All Discussions</p>
                    </div>
                  </div>
                </div>
                <div
                  className={`col-6 category${tab === 'self' ? ' active' : ''}${token ? '' : ' disabled'}`}
                  onClick={() => token && this.selectThreads('self')}
                >
                  <div className='row'>
                    <div className='col-12'>
                      <p className='category-title text-center'>My Discussions</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-md-8 col-lg-10'>
              <div className='filters-on-block forum hidden-on-desktop'>
                <div className='discussions-holder category-holder'>
                  {token && <div className='col-12 category mb-3 text-center add-one-more' onClick={() => browserHistory.push('/nutrition-support/forum/add')}>
                    <div className='row'>
                      <div className='col-12'>
                        <p className='category-title'>+ Add new</p>
                      </div>
                    </div>
                  </div>}
                  {categoriesMenu}
                </div>
              </div>

              <div className='row masonry-wrap d-flex'>
                <Masonry
                  className={'masonry col-12'} // default ''
                  elementType={'div'} // default 'div'
                  disableImagesLoaded={false} // default false
                  updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                >
                  {items.length ? items : this.renderPlaceholder()}
                </Masonry>
              </div>
            </div>
            <div className='col-4 col-lg-2 hidden-md-down'>
              <div className='row discussions-holder category-holder'>
                {token && <div className='col-12 category mb-3 text-center add-one-more' onClick={() => browserHistory.push('/nutrition-support/forum/add')}>
                  <div className='row'>
                    <div className='col-12'>
                      <p className='category-title'>+ Add new</p>
                    </div>
                  </div>
                </div>}
                {categoriesMenu}
              </div>
            </div>
          </div>
        </div>
        {!window.DISQUSWIDGETS && <Script
          url={counterUrl}
          onLoad={this.scriptLoaded}
          attributes={{
            id: 'dsq-count-scr',
            async: true
          }}
        />}
        <ModalWrapper
          show={modal}
          close={() => this.setState({ modal: false, modalCallback: () => {} })}
          md={4}
        >
          <div className={'order-modify-modal'}>
            {this.renderDeleteModal(modalCallback)}
          </div>
        </ModalWrapper>
      </div>
    );
  }
}

Forum.propTypes = {
  socket: PropTypes.object.isRequired,
  token: PropTypes.any.isRequired,
  userId: PropTypes.any,
  settings: PropTypes.object,
  forumCategories: PropTypes.object
};

Forum.defaultProps = {
  forumCategories: {}
};

const mapStateToProps = state => ({
  forumCategories: state.dataArray.forumCategories,
  language: state.user.language,
  token: state.user.token,
  userId: state.user['user_id'],
  settings: state.settings
});

export default socketConnect(connect(mapStateToProps, null)(Forum));
