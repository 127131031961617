import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import { Link, browserHistory } from 'react-router';
import InputValidation from '../../../../components/InputValidation';
import SelectValidation from '../../../../components/SelectValidation';
import PaperButton from 'react-paper-button';
import TinyMCE from 'react-tinymce';
import Script from 'react-load-script';
import { getDataArray } from '../../../../store/dataArray';

class EditThread extends Component {
  constructor(props) {
    super(props);
    const { id: threadId } = props.params;
    this.state = {
      threadId,
      threadData: {},
      errors: {},
      tinymceLoaded: false,
      xyz: false
    };
    this.getThreadData(threadId);
  }

  getThreadData = threadId => {
    this.props.socket.emit('forum', {
      type: 'get',
      data: {
        threadId
      }
    });
  };

  listener = action => {
    if (this.EditThreadRef) {
      switch (action.type) {
        case 'getOk':
          const { threadData } = action.data;
          let text = '';
          try {
            text = threadData.text ? decodeURI(atob(threadData.text)) : '';
          } catch (e) {
            text = threadData.text;
          }
          this.setState({ threadData: { ...threadData, text } });
          break;
        case 'setOk':
          browserHistory.push(`/nutrition-support/forum/thread/${action.data.threadId}`);
          break;
        case 'getErr':
          browserHistory.push('/nutrition-support/forum');
          break;
        case 'setErr':
          this.setState({ errors: { ...action.data.errors } });
          console.log(this.state.errors);
          break;
      }
    }
  };

  componentWillMount() {
    this.props.socket.on('forum', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('forum', this.listener);
  }

  componentDidMount() {
    if (this.EditThreadRef) {
      const { forumCategories, socket } = this.props;
      if (socket && !Object.keys(forumCategories).length) {
        getDataArray({ keys: ['forumCategories'], socket });
      }
    }
    setTimeout(() => this.setState({ xyz: true, tinymceLoaded: !!window.tinymce }), 300);
  }

  submit = e => {
    e.preventDefault();
    const { params } = this.props;
    let threadData = { ...this.state.threadData };
    threadData.text = btoa(encodeURI(threadData.text));
    const data = params.id && params.id !== 'add' ? { threadData, threadId: params.id } : { threadData };
    this.props.socket.emit('forum', { type: 'set', data });
  };

  render() {
    const { xyz, threadData, errors, tinymceLoaded } = this.state;
    const { forumCategories } = this.props;
    const changeData = (name, value) => this.EditThreadRef && this.setState({ threadData: { ...threadData, [name]: value } });
    const flag = !!Object.keys(threadData).length;
    return (
      <div className={`main-holder d-flex flex-column ${xyz ? 'xyz-fin' : 'xyz'}`} ref={el => (this.EditThreadRef = el)}>
        {!window.tinymce && <Script url='//healthyme.loseweight.ae/api/js/tinymce/tinymce.min.js' onLoad={() => this.setState({ tinymceLoaded: true })} />}
        <div className='container-fluid bg-gray-blog height-papa blog-page custom-scrollbar vertical-scrollbar' onScroll={this.onScrollHandler}>
          <div className='row title-holder blog-heading '>
            <div className='col-12  d-flex flex-column justify-content-center title-block blog'>
              <h1>Forum</h1>
              <p>
                <span className='ar-golr-l' />
                <span>Create new thread</span>
                <span className='ar-golr-r' />
              </p>
            </div>
          </div>
          <div className='row support-holder add-form-holder'>
            <div className='col-12'>
              <div className='all-my p-0'>
                <div className='blog-filters p-0'>
                  <div className='back-holder'>
                    <Link to='/nutrition-support/forum' className='back-link'>
                      <span className='back'>&larr;</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className='row add-thread-form'>
                <div className='col-12 p-0'>
                  <div className='bg-gr-light-2'>
                    <h5 className='green'>Title</h5>
                    <InputValidation
                      name={'title'}
                      value={threadData['title'] || ''}
                      placeholder='Title'
                      onChange={changeData}
                      errors={errors}
                    />
                  </div>
                </div>
                <div className='col-12 p-0 d-flex flex-column justify-content-between'>
                  <div className='bg-gr-light-2'>
                    <h5 className='green bold'>Category</h5>
                    <div className='b-shadowed-gray n-shadow b-rounded-blue'>
                      <SelectValidation
                        name={'category_id'}
                        value={threadData['category_id'] || ''}
                        onChange={changeData}
                        errors={errors}
                        list={forumCategories}
                        emptyValue={'Choose category'}
                        canEmpty
                      />
                    </div>
                  </div>
                </div>
                <div className='col-12 p-0'>
                  <div className=' padded'>
                    <h5 className='green bold'>Text</h5>
                    {errors.text && <span className='has-error letter-xs red-color'>{errors.text}</span>}
                    {(window.tinymce && tinymceLoaded && flag) && <TinyMCE
                      content={threadData['text']}
                      config={{
                        plugins: 'autolink link lists print image imagetools preview code',
                        toolbar: 'undo redo | bold italic | alignleft aligncenter alignright code',
                        min_height: 200,
                        imagetools_toolbar: 'imageoptions'
                      }}
                      onChange={e => changeData('text', e.target.getContent())}
                    />}
                  </div>
                </div>
                <div className='col-md-9' />
                <div className='col-12 col-md-3 mt-3' onClick={this.submit} >
                  <span className='button p-2 text-center d-block transition next class-for-mobile'>Save</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditThread.propTypes = {
  socket: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  forumCategories: PropTypes.object
};

EditThread.defaultProps = {
  forumCategories: {}
};

const mapStateToProps = state => ({
  forumCategories: state.dataArray.forumCategories,
  token: state.user.token
});

export default socketConnect(connect(mapStateToProps, null)(EditThread));
