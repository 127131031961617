import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Day from './Day';
import moment from 'moment';
import ToolTip from 'components/ToolTip';
import { Alert } from 'react-bootstrap';
import SelectInput from 'components/Select';
class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xyz: false, 
      date: false,
      isSave:false,
      editdaylist:{}
    };
  } 
  setUnsavedVal=(action,index,popupshow=false)=>{
    var {editdaylist}=this.state;
    if(!popupshow){
      if(action=='pop'){
        delete editdaylist[index]  //delete a key
      }else{
        editdaylist[index]='test';
      }
    }
    var isshow=(Object.keys(editdaylist).length>0&&popupshow)?true:false;
    this.setState({ editdaylist: editdaylist,isSave:isshow });
  }
  componentDidMount() {
    this.props.onReady();
  } 
  changeForm = e => {
    const { period } = this.props;
    let date = e.utc().startOf('day');
    if (date.isBetween(period.from, period.to, null, '[]')) {
      // this.props.onChange(date.startOf('week'));
      this.props.onChangeDate(date);
    }
  };

  showOnMobileBlockToggler = (block) => {
    const { customSelectClicked } = this.props;
    if (window.innerWidth <= 768 || screen.width <= 768) {
      this.setState({isShowMenu:true});
      document.querySelector('body').scrollIntoView(); 
    }
  };
  handleonBlur = () => {
    this.setState(({
      isShowMenu: false,
    }))   
  };
  handleOnFocus = () =>{
    this.setState(({
      isShowMenu: true,
    }))  
  };

  renderMobileDays(){
    const { mealPlan } = this.props;
    const date = this.state.date || this.props.date;
    var dayslist={};
    Object.keys(mealPlan).map(function(dateIndex){
          dayslist[dateIndex] = 'Jump to date - '+moment.unix(dateIndex).format("dddd, Do MMM");
      });
      return (<div className="mealdays-selection"><SelectInput
      labelText='Select My Meals'
      list={dayslist}
      value={{value:date.unix(),label:date.format("dddd, Do MMM")} || {}}
      name='mealday'
      searchable={false}
      menuIsOpen={this.state.isShowMenu}
      onBlur ={ this.handleonBlur}
      onFocus ={this.handleOnFocus}
      escapeClearsValue={true}
      onChange={ 
          (name,value) => {  this.changeForm(moment.unix(value.value)); this.setState({isShowMenu:false}); }
        }
    /></div>);
  }
  renderDietChangeMsg =(preDietId,dietId,date)=>{
    const { dietList } =this.props;
    return <p className='diet-msg'>
      Plan Changed from <b className='diet-name-edit'>{dietList[preDietId]}</b> to <b className='diet-name-edit'>{dietList[dietId]}</b> on {date && date.format('Do MMM YYYY')}
    </p>
  }
  render() {
    const { goMenu, excludeWeekDay, period, isMob, skipped, changeSkipped, skipTheDay, originTo, mealPlan, planTitle, planName, showSwitcher, curModType, switchModType, placeOrder, customPlaceOrder, ratings, user, pause, pause_start, pause_end, dietproducts, customdays, usersides, sidesList, oilList, buySidesList,dietChanges,dietList,diet , customMealPlan } = this.props;
    console.log(dietChanges);
    console.log(period);
    let diet_id=(dietChanges!=undefined)?dietChanges[period.from.unix()] || diet:diet;
    const date = this.state.date || this.props.date;
    const endDay = moment(period.to).clone().subtract(1, 'days');
    const nextModType = { active: 'future', future: 'active' }[curModType];
    const week = date
      ? Array(isMob ? 128 : 128)
        .fill(0)
        .map((el, key) => key)
        .filter(el => {
          const curd = date.clone().add(el, 'day');
          return curd.isBetween(period.from, period.to, null, '[]') && (curd.unix() in mealPlan || Object.values(skipped || {}).includes(curd.unix()));
        })
        //.filter(el => !excludeWeekDay.includes(date.clone().add(el, 'day').weekday()))
        .map(id => {
          const day = date.clone().add(id, 'day');
          const inactive = day.isBefore(moment().add(48, 'h'));
         
          if (dietproducts) {

            let changemsg='';
            if(day.unix()===period.from.unix()){
                changemsg=<p className='diet-name-edit'>{dietList[diet_id]}</p>;
            }

            var custom = false;
            if (typeof(customdays[day.unix()]) != 'undefined') {
              custom = true;
            }
            if(dietChanges!=undefined && diet_id!=dietChanges[day.unix()]&&!custom){
              changemsg=this.renderDietChangeMsg(diet_id,dietChanges[day.unix()],day);
              diet_id=dietChanges[day.unix()];
            }
            return (
              <div>
              <Day
                {...{ key: id, date: day, goMenu, inactive, skipped, originTo }}
                changeSkipped={unix => changeSkipped(unix, period.to.unix(), id)}
                skipTheDay={unix => skipTheDay(unix, period.to.unix(), id)}
                placeOrder={placeOrder}
                pause={pause}
                usersides={usersides}
                sidesList={sidesList}
                buySidesList={buySidesList}
                oilList={oilList}
                dietproducts={dietproducts}
                pause_start={pause_start}
                pause_end={pause_end}
                user={user}
                ratings={ratings}
                customdays={customdays}
                customMealPlan={customMealPlan}
                setUnsavedVal={this.setUnsavedVal}
                editdaylist = {this.state.editdaylist}
                diet_id={diet_id}
              />
              {custom && 
                <Day
                {...{ key: id, date: day, goMenu, inactive, skipped, originTo }}
                changeSkipped={unix => changeSkipped(unix, period.to.unix(), id)}
                skipTheDay={unix => skipTheDay(unix, period.to.unix(), id)}
                placeOrder={placeOrder}
                customPlaceOrder={customPlaceOrder}
                usersides={usersides}
                pause={pause}
                dietproducts={dietproducts}
                custom={true}
                mealPlan={customdays}
                customdays={customdays}
                customMealPlan={customMealPlan}
                pause_start={pause_start}
                pause_end={pause_end}
                user={user}
                ratings={ratings}
                setUnsavedVal={this.setUnsavedVal}
                editdaylist = {this.state.editdaylist}
                diet_id={diet_id}
              />
              }
              </div>

              
            );
          } 
        }) : [];
    return (
      <Fragment>
       <div className={` dish-holder ${!isMob ? 'custom-scrollbar vertical-scrollbar' : ''}`} ref={el => (this.MainRef = el)}>
        <div className='offset-top' />
        {this.state.isSave&&<Alert bsStyle="unsaved" ><span className="warning">!</span><strong>Please click on "Save Changes" button before moving to the next day. Highlighted items are the selected ones.</strong></Alert>}
        <div className='head-line'>
        
            <h5>
             <span className="diet-name-edit">{planName}</span> <span className="diet-overview-edit">{planTitle}</span> <br/> <span className='mini'>{period.from.format('dddd, Do MMM')} - {endDay.format('dddd, Do MMM')}</span>
            </h5>
          
          {showSwitcher && <div className={`mod-type-switcher ${nextModType}`} onClick={() => switchModType(nextModType)}>View my { (nextModType == 'future') ? 'renewed' : 'active' } plan</div>}
          {isMob && this.renderMobileDays()}
        </div>
        {week}
        <div>
          {showSwitcher && <div className={`bottom-mod-type-switcher ${nextModType}`} onClick={() => switchModType(nextModType)}>View my { (nextModType == 'future') ? 'renewed' : 'active' } plan</div>}
        </div>
      </div>
        <div className='col-2 col-sm-2 btnb text-center backtotop mtop togle-dishes-menu'>
            <p className={`regular-button next`}
              onClick={() => this.showOnMobileBlockToggler('dishes')}
            ><i class="fa fa-chevron-up" aria-hidden="true"></i></p>
          </div>
     </Fragment> 
    );
  }
}

Main.propTypes = {
  period: PropTypes.object.isRequired,
  planName: PropTypes.string.isRequired,
  planTitle: PropTypes.string.isRequired,
  date: PropTypes.object.isRequired,
  originTo: PropTypes.object.isRequired,
  excludeWeekDay: PropTypes.array.isRequired,
  changeSkipped: PropTypes.func.isRequired,
  skipped: PropTypes.object.isRequired,
  mealPlan: PropTypes.object.isRequired,
  goMenu: PropTypes.func,
  onReady: PropTypes.func,
  isMob: PropTypes.any,
  customPlans: PropTypes.array,
  showSwitcher: PropTypes.bool,
  curModType: PropTypes.string,
  switchModType: PropTypes.func
};

export default Main;
