export const SET_CHECKOUT_PLAN_PARAMS = 'SET_CHECKOUT_PLAN_PARAMS';
export const UPDATE_CHECKOUT_PLAN_PARAMS = 'UPDATE_CHECKOUT_PLAN_PARAMS';
export const CLEAR_CHECKOUT_PLAN_PARAMS = 'CLEAR_CHECKOUT_PLAN_PARAMS';

const initialState = {};

export function setCheckoutPlanParams(obj) {
  return {
    type    : SET_CHECKOUT_PLAN_PARAMS,
    payload : obj
  };
}

export function updateCheckoutPlanParams(obj) {
  console.log(obj);
  return {
    type    : UPDATE_CHECKOUT_PLAN_PARAMS,
    payload : obj
  };
}

export function clearCheckoutPlanParams() {
  return {
    type    : CLEAR_CHECKOUT_PLAN_PARAMS
  };
}

export default function checkoutPlanParamsReducer(state = initialState, action) {

  if (action.type === SET_CHECKOUT_PLAN_PARAMS) {
    return (action.payload==undefined)?null:action.payload;
  } 
  if (action.type === UPDATE_CHECKOUT_PLAN_PARAMS) {
        return { ...state, ...action.payload };
  }
  if (action.type === CLEAR_CHECKOUT_PLAN_PARAMS) {
    return initialState;
  }
  return state;
}
