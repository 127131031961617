export const SET_CHECKOUT_MEAL_PLAN_SIDES = 'SET_CHECKOUT_MEAL_PLAN_SIDES';
export const UPDATE_CHECKOUT_MEAL_PLAN_SIDES = 'UPDATE_CHECKOUT_MEAL_PLAN_SIDES';
export const CLEAR_CHECKOUT_MEAL_PLAN_SIDES = 'CLEAR_CHECKOUT_MEAL_PLAN_SIDES';
export const CHECKOUT_DELETE_MEAL_PLAN_SIDES = 'CHECKOUT_DELETE_MEAL_PLAN_SIDES';

const initialState = {};

export function setCheckoutMealPlanSides(obj) {
  return {
    type    : SET_CHECKOUT_MEAL_PLAN_SIDES,
    payload : obj
  };
}

export function updateCheckoutMealPlanSides(date, obj) {
  return {
    type    : UPDATE_CHECKOUT_MEAL_PLAN_SIDES,
    payload : { date, obj }
  };
}
export function deleteCheckoutMealPlanSides(date) {
  return {
    type    : CHECKOUT_DELETE_MEAL_PLAN_SIDES,
    payload : { date }
  };
}

export function clearCheckoutMealPlanSides() {
  return {
    type    : CLEAR_CHECKOUT_MEAL_PLAN_SIDES
  };
}

export default function checkoutMealPlanSidesReducer(state = initialState, action) {
 
  if (action.type === SET_CHECKOUT_MEAL_PLAN_SIDES) {
    return (action.payload==undefined)?null:action.payload;
  } 
  if (action.type === UPDATE_CHECKOUT_MEAL_PLAN_SIDES) {
        const { date, obj } = action.payload;
        return { ...state, [date]: obj };
  }
  if(action.type === CHECKOUT_DELETE_MEAL_PLAN_SIDES) {
    const { date } = action.payload;
    const filtered = Object.keys(state)
         .filter(key => date!=key)
         .reduce((obj, key) => {
           obj[key] = state[key];
           return obj;
         }, {});
    return { ...filtered };
 }
  if (action.type === CLEAR_CHECKOUT_MEAL_PLAN_SIDES) {
    return initialState;
  }
  return state;
}
