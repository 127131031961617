import React, { Component } from 'react';
import PropTypes from 'prop-types';

class RadioInputNew extends Component {
  constructor(props){
    super(props);
    this.state={
      checkedValue:''
    }
  }
  componentDidMount(){
    this.setState({checkedValue:this.props.value});
  }
  render() {
    const { datas, name, className,label, onChange, errors, classError,isHorizontal } = this.props;
    const { checkedValue } = this.state;
    const radioList =datas.map((data)=>{
        const { message } =data;
      return <li>
        <input type="radio" value={data.value} name={name} id={'label-'+name+data.value} checked={data.value==checkedValue} onChange={(e)=>{this.setState({checkedValue:e.target.value},() => {onChange(name,this.state.checkedValue)})}} /> <label for={'label-'+name+data.value}>{data.title} {message && <span>{message}</span>}</label>
        </li>;
  });
    return (
      <div className={className}>
            {label!=undefined && label && <label>{label}</label>}
            <ul className={`radio-list ${(isHorizontal) ? 'horiz-list' : ''}`}>
            {radioList}
            </ul>
        </div>
    );
  }
}

RadioInputNew.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  isHorizontal:PropTypes.bool,
  classError: PropTypes.string,
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool
  ]),
  datas: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool
      ]).isRequired
    })
  ),
  onChange: PropTypes.func.isRequired
};

RadioInputNew.defaultProps = {
  errors: {},
  elements: [],
  classError: 'has-error',
  value: false,
  isHorizontal:false,
  className: 'step-form-input'
};

export default RadioInputNew;
