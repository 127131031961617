import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import { setModal } from '../../store/modal';
import PaperButton from 'react-paper-button';
import HeadLine from '../../layouts/PageLayout/HeadLine';
import ProfileForm from 'components/ProfileForm';

class Profile extends Component {
  constructor(props) {
    super(props);
    const { firstName, lastName, phone, gender, weight, height, birthday, medicalCondition, email, language, homeAddress, publicAddress, workAddress,allergies, dislikes, dishlikes, dishdislikes } = this.props.user;
    const { medicalConditions } = this.props.dataArray  || {};
    let medicaldata = [];
      if(Array.isArray(medicalCondition)){
        medicalCondition.map(value => {
                medicaldata.push({ value:value.toString(), label: medicalConditions[value] });
          });
      }
    this.state = {
      userData: { firstName, lastName, phone, gender, weight, height, birthday, medicalCondition, email, language, homeAddress, publicAddress, workAddress,allergies, dislikes, dishlikes, dishdislikes },
      errors: {},
      xyz: false
    };
  }

  submit = () => this.props.socket.emit('profile', { type: 'set', data: { ...this.state.userData, scenario: 'main' } });

  componentDidMount() {
    setTimeout(() => this.setState({ xyz: true }));
    this.props.socket.emit('get_arrays', {
      type: 'get',
      data: {
        type: ['gender', 'language','areasList','allEmirates','ingredientList','productsFull','medicalConditions'],
        language: this.props.user.language
      }
    });
  }

  componentWillMount() {
    this.props.socket.on('profile', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('profile', this.listener);
  }

  setNotification(changes, thetype) {
	  
      const {user} = this.props;
      this.props.socket.emit('profile', {type: 'getForDashboard', data: {
            'subtype': 'setNotification', 
            'userId': user.user_id,
            'changes': changes,
            'thetype': thetype,
          } 
      });
  }

  listener = action => {
    if (this.ProfileRef) {
      const { setModal } = this.props;
      switch (action.type) {
        case 'setOk':
          setModal({ message: action.message });
          break;
        case 'setErr':
          this.setState({ errors: action.data.errors });
          action.message && setModal({ message: action.message });
          break;
      }
    }
  };

  onChange = (name, value) => this.ProfileRef && this.setState({ userData: { ...this.state.userData, [name]: value } });

  render() {
    const { submit, onChange } = this;
    const { xyz, errors, userData } = this.state;
    const { dataArray, user } = this.props;
    const listGender = 'gender' in dataArray ? dataArray['gender'] : {};
    const listLanguage = 'language' in dataArray ? dataArray['language'] : {};
    const areasList = 'areasList' in dataArray ? dataArray['areasList'] : {};
    const allEmirates = 'allEmirates' in dataArray ? dataArray['allEmirates'] : {};
    const ingrediantsList = 'ingredientList' in dataArray ? dataArray['ingredientList'] : {};    
    const productFullList = 'productsFull' in dataArray ? dataArray['productsFull'] : {};
    const medicalConditions = 'medicalConditions' in dataArray ? dataArray['medicalConditions'] : {};
    return (
      <div className={`main-holder d-flex flex-column ${xyz ? 'xyz-fin' : 'xyz'}`} ref={el => (this.ProfileRef = el)}>
        <HeadLine
          title='Profile Settings'
          desc='Please tell us some info about yourselfs'
        />
        <ProfileForm
          userData={userData}
          errors={errors}
          listGender={listGender}
          listLanguage={listLanguage}
          allEmirates={allEmirates}
          areasList={areasList}
          ingrediantsList={ingrediantsList}
          productFullList={productFullList}
          medicalConditions={medicalConditions}
          locale={(user.language || 'en-US').substr(-2)}
          onChange={onChange}
        />
        <div className='container-fluid bottom-row class-for-mobile'>
          <div className='row step-holder bottom-buttons-holder'>
            <div className='col-3 m-0 p-0' />
            <div className='col-6 bg-1-2' />
            <div className='col-12 col-md-3 m-0 p-0 class-for-mobile'>
              <PaperButton className='button-regular transition next' onClick={ e => {
                  this.setNotification('Profile information is changed from the full profile info page', 1);
                  submit();
                }
              }>
                <span>Save</span>
              </PaperButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Profile.propTypes = {
  socket: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  dataArray: state.dataArray
});

const bindAction = dispatch => ({
  setModal: data => dispatch(setModal(data))
});

export default socketConnect(connect(mapStateToProps, bindAction)(Profile));
