import React,{ Component } from "react";

class Notpaidnotification extends Component{
    constructor(props){
        super(props);
    }

    render() {
        return <div className={`notpaid-notifiy`}>
             <div className="container checkout-process">
                        <div className="row">
                        <div className='col-12'>
            <p><strong>Note: This plan is not paid.</strong> The plan will not start until paid.</p>
            <p className="info-text">Delivery start date is adjusted to 2 days after the payment date.</p>
    </div></div></div></div>;
    }
}
export default Notpaidnotification;