import React,{ Component, Fragment } from "react";
import PaperButton from 'react-paper-button';
import { browserHistory } from 'react-router';
import RightArrow from 'assets/btn-right-arrow.png';
import LeftArrow from 'assets/btn-left-arrow.png';
import SaveIcon from 'assets/save.png';
import DeleteIcon from 'assets/delete.png';
import PDFIcon from 'assets/pdf.png';
import Price from './Price';
import ToolTip from 'components/ToolTip';
class BottomNav extends Component{
    constructor(props){
        super(props);
    }
    onContinue(action){
        const { continueValid } = this.props;
        let valid=true;
        if(continueValid!=undefined){
            valid=continueValid();
        }
        if(valid){
            browserHistory.push(action);
        }
    }
    renderButtons(pathname){
     
        let validStep = [];
        validStep.push(pathname);
        switch(pathname){
            case '/checkout/meal-types-and-days':
                validStep.push('/checkout');
                return <Fragment><PaperButton onClick={() => { browserHistory.push('/checkout'); }} className='button-small transition back-btn'>
                    <span><img src={LeftArrow} /></span>
                <span className="icon">Back</span>
              </PaperButton>
              <PaperButton onClick={() => { this.onContinue('/checkout/delivery-dates'); }} className='button-small transition continue'>
                <span>Continue</span>
                <span className="icon"><img src={RightArrow} /></span>
              </PaperButton></Fragment>;
            break;
            case '/checkout/delivery-dates':
                validStep.push('/checkout');
                validStep.push('/checkout/meal-types-and-days');
                return <Fragment><PaperButton onClick={() => {  browserHistory.push('/checkout/meal-types-and-days'); }} className='button-small transition back-btn'>
                    <span><img src={LeftArrow} /></span>
                <span className="icon">Back</span>
              </PaperButton>
              <PaperButton onClick={() => { this.onContinue('/checkout/menus');  }} className='button-small transition continue'>
                <span>Continue</span>
                <span className="icon"><img src={RightArrow} /></span>
              </PaperButton></Fragment>;
            break;
            case '/checkout/menus':
                return <Fragment>
                  <PaperButton onClick={() => {  this.props.viewMenuPDF(); }} className='button-small transition light-blue-btn'>
                  View Menu in PDF
              </PaperButton>
                  <PaperButton onClick={() => {  browserHistory.push('/'); }} className='button-small transition light-blue-btn'>
                   Save for later
              </PaperButton>
              <PaperButton onClick={() => {  this.props.removeCheckout(); }} className='button-small transition back-btn'>
                  Delete & Startover
              </PaperButton>
                  <PaperButton onClick={() => { this.onContinue('/checkout/summary'); }} className='button-small transition continue'>
                <span>Continue to Checkout</span>
                <span className="icon"><img src={RightArrow} /></span>
              </PaperButton></Fragment>;
            break;
            default:
                return <Fragment>
              <PaperButton onClick={() => { this.onContinue('/checkout/meal-types-and-days'); }} className='button-small transition continue'>
                <span>Continue</span>
                <span className="icon"><img src={RightArrow} /></span>
              </PaperButton></Fragment>;
            break;
        }
    }
    renderPriceData(){
      const { priceDatas, isShowBag } = this.props;
      return <Price priceDatas={priceDatas} isShowBag={isShowBag} />;
    }
    renderButton(){
        return <div className="mobile-action-btn">
          <ToolTip
                  trigger={['click']}
                 tooltipText={<PaperButton onClick={() => {  browserHistory.push('/'); }} className='button-small transition light-blue-btn'>
                 View Menu in PDF
            </PaperButton>}
            >
            <a href="#"><img src={PDFIcon} alt="View Menu in PDF" /></a> 
            </ToolTip>
          <ToolTip
                  trigger={['click']}
                 tooltipText={<PaperButton onClick={() => {  browserHistory.push('/'); }} className='button-small transition light-blue-btn'>
                 Save for later
            </PaperButton>}
            >
            <a href="#"><img src={SaveIcon} alt="Save for later" /></a> 
            </ToolTip>
            <ToolTip
              tooltipText={<PaperButton onClick={() => {  this.props.removeCheckout(); }} className='button-small transition back-btn'>
              Delete & Startover
          </PaperButton>}
              trigger={['click']}
            >
          <a href="#"><img src={DeleteIcon} alt="Delete & Startover" /></a> 
          </ToolTip>
        </div>
    }
    render() {
      const { priceDatas } = this.props;
      const { pathname  } = browserHistory.getCurrentLocation();
        return <div className={`step-bottom-nav ${(priceDatas!=undefined) ? 'border-top' : ''}`}>
            <div className="step-bottom-nav-left">
            {this.renderPriceData()}
            {pathname =='/checkout/menus' && this.renderButton()}
            </div>
            <div className="step-bottom-nav-right">
                {this.renderButtons(pathname)}
            </div>
        </div>;
    }
}
export default BottomNav;