import React, { Component } from 'react';
import SelectInput from 'components/Select';
// import { Select2 } from "select2-react-component";
import ReactSelect from 'react-select';
// import 'react-select/dist/react-select.css';
// import Select2 from 'react-select2-wrapper';
class SidesModal extends Component {
  constructor(props) {
    super(props);
    const {orderId,typeId,productId,type,usersides,date,products,bagName} = this.props;
    var sides=[];
    if (typeof(usersides[date]) != 'undefined') {
      Object.keys(usersides[date]).map( (pr_id, idx) => {
        if (pr_id == productId) {
          
          {usersides[date][pr_id][bagName]!= 'undefined'&&Object.keys(usersides[date][pr_id][bagName][typeId]).map( (el, idn) => {
            var side = usersides[date][pr_id][bagName][typeId][el];
            if(side.type==type&&typeof(products[side.side_id]) != 'undefined'){
              sides.push({label:products[side.side_id].title,value:side.side_id});
            }
          })};
        }
      });
    }
    
    this.state = {
      sides,
      thetype: null,
      sidesList:[],
      type
    };
    if(type=='alternative'){
      this.getSidesListByMeal(orderId,typeId,productId);
    }else{
      this.getBuySidesListByMeal(orderId,productId);
    }
  }
  getSidesListByMeal = (order_id,meal_type_id,product_id) => this.props.socket.emit('menu_builder', { type: 'getSidesListClient', data: { orderId: order_id,mealTypeId: meal_type_id,ProID: product_id ,is_select2:true} });
  getBuySidesListByMeal = (order_id,product_id) => this.props.socket.emit('menu_builder', { type: 'getBuySidesList', data: { orderId: order_id,ProID: product_id} });
  listener = ({ type, data }) => {
    // const {user_id, order_id} = data;
    switch (type) {
      case 'getSidesListClientOk':
        this.setState({
          sidesList: data,
        });
      break;
      case 'getBuySidesListOk':
        this.setState({
          sidesList: data,
        });
      break;
    }
  }
  componentWillMount() {
    this.props.socket.on('menu_builder', this.listener);
  }
  componentWillUnmount() {
    this.props.socket.removeListener('menu_builder', this.listener);
  }
  onChange = (type, value) => {
    console.log(type);
    console.log(value);
    this.setState({
      [type]: value
    });
  }
  
  render() {
    const { sides, thetype,sidesList } = this.state;
    // console.log(sidesList);
    const { productId, date,usersides, products,multiple,bagName,typeId} = this.props;
   
    var types = {
      'alternative' : 'Alternative',
      'addons' : 'Additional',
    };
    var sideshow = [];
    if (typeof(usersides[date]) != 'undefined') {
      Object.keys(usersides[date]).map( (pr_id, idx) => {
        if (pr_id == productId) {
          Object.keys(usersides[date][pr_id][bagName][typeId]).map( (el, idn) => {
          var side = usersides[date][pr_id][bagName][typeId][el];
            if (typeof(products[side.side_id]) != 'undefined') {
              var fat = products[side.side_id].fat/1000;
              var carb = products[side.side_id].carb/1000;
              var protein = products[side.side_id].protein/1000;
              var calories = products[side.side_id].calories/1000;
              var name = products[side.side_id].title;
              sideshow.push(
                <div>
                  <div >
                    {name} #{side.side_id}
                  </div>
                  <div >
                    Fat: {fat}
                  </div>
                  <div >
                    Protein: {protein}
                  </div>
                  <div >
                    Carbs: {carb}
                  </div>
                  <div >
                    Calories: {calories}
                  </div>
                  <div >
                    {side.type}
                  </div>
                  </div>
              );
            }
          })
        }
      });
    }
    const options = [
      { value: 'chocolate', label: 'Chocolate' },
      { value: 'strawberry', label: 'Strawberry' },
      { value: 'vanilla', label: 'Vanilla' }
    ]
    return (
      <div>
        {/* <h3 className="list-subhead">Customer's optional sides</h3>
        <span>
          Alternative - in use instead of default sides <br/>
          Addons - additional sides (default or alternative + addons [purchased sides are under this list]) <br/>
        </span><br/><br/>
        <div className="sides-text">
          <div className="underline">
         
            {sideshow}
          
          </div>
        </div> */}
        <div className='edit-modal-inner edit-sides-modal' style={{ paddingBottom: '40px' }}>
              {sidesList &&<SelectInput 
                placeholder='Sides'
                isgrouped
                multiple={multiple}
                list={sidesList}
                value={sides || ''}
                onChange={(name,value) =>this.onChange('sides', value)}
                />}
        </div>
        
      </div>
    );
  }
}

// SidesModal.propTypes = {
//   save: PropTypes.func.isRequired
// };

export default SidesModal;