import React,{ Component, Fragment } from "react";
import Carousel, { arrowsPlugin, Dots } from '@brainhubeu/react-carousel';
class ProductCarousel extends Component {
    constructor(props) {
        super(props);
        this.state={
            value:0
        }
        this.onSliderChange = this.onSliderChange.bind(this);
    }
    onSliderChange(value){
      console.log(value);
        this.setState({ value });
    }
    render(){
        const { breakpoints,slidesPerPage,slides,showArrow } = this.props;
        const showArrows = slides && showArrow && slides.length > slidesPerPage;
        return <div className="product-carousel">
        <Dots number={slides.length}  value={this.state.value} onChange={this.onSliderChange}  />
        <Carousel
        value={this.state.value}
      arrowLeft={showArrows?<span className="car-arrow"><i className="fa fa-chevron-left"></i></span>:null}
      arrowRight={showArrows?<span className="car-arrow"><i className="fa fa-chevron-right"></i></span>:null}
      addArrowClickHandler={showArrows}
      onChange={this.onSliderChange}
      slidesPerPage={slidesPerPage}
      slidesPerScroll={slidesPerPage}
      slides={slides}
      breakpoints={breakpoints}
      plugins={[
        {
          resolve: arrowsPlugin,
          options: {
            arrowLeft: <span className="car-arrow"><i className="fa fa-chevron-left"></i></span>,
            arrowLeftDisabled:<span className="car-arrow"><i className="fa fa-chevron-left"></i></span>,
            arrowRight: <span className="car-arrow"><i className="fa fa-chevron-right"></i></span>,
            arrowRightDisabled: <span className="car-arrow"><i className="fa fa-chevron-right"></i></span>,
            addArrowClickHandler: true,
          }
        }
      ]}
      /></div>;
    }
}
export default ProductCarousel;