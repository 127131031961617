import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { socketConnect } from 'socket.io-react';

class Reviews extends Component {
  constructor(props) {
    super(props);
    const { dishId } = this.props;
    this.state = {
      reviews: false
    };
    dishId && this.getReviews(dishId);
  }

  componentWillMount() {
    this.props.socket.on('rating', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('rating', this.listener);
  }

  listener = action => {
    if (this.ReviewsRef) {
      switch (action.type) {
        case 'getDishReviewsOk':
          this.setState(action.data);
          break;
        case `${action.type}Err`:
          console.error(action.data.message);
          break;
      }
    }
  };

  getReviews = dishId => {
    this.props.socket.emit('rating', {
      type: 'getDishReviews',
      data: { dishId }
    });
  };

  componentWillReceiveProps(next) {
    if (this.ReviewsRef && next.dishId !== this.props.dishId) {
      this.getReviews(next.dishId);
    }
  }

  render() {
    const { reviews } = this.state;
    const getRating = rating => Array(5).fill(0).map((el, idx) => <i key={idx} className={`icon-star mx-1 letter-normal ${idx < rating ? 'green' : 'light-dark-text'}`} />);
    const list = reviews && reviews.map((el, idx) => {
      const { username, rating, feedback } = el;
      return (
        <li className='review' key={idx}>
          <div className='row'>
            <div className='col-6'>
              <span>{username}</span>
            </div>
            <div className='col-6 text-right'>
              <p className='mb-0'>
                {getRating(rating)}
              </p>
            </div>
          </div>
          <p>{feedback}</p>
        </li>
      );
    });
    const reviewsList = (
      <ul className='reviews-list'>
        {list.length ? list : <li className='review' >There are no reviews</li>}
      </ul>
    );
    return (
      <div ref={el => (this.ReviewsRef = el)}>
        <p className='regular-bold green'>Reviews</p>
        {reviews === false ? 'spinner' : reviewsList}
      </div>
    );
  }
}

Reviews.propTypes = {
  socket: PropTypes.object.isRequired,
  dishId: PropTypes.number.isRequired
};

export default socketConnect(Reviews);
