import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PaperButton from 'react-paper-button';
import Select from 'components/Select';
import './style.css';


class EliminateGroupsModal extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      xyz: false,
      allergies: [],
      eliminate_groups: [],
      dishlikes: [],
      dishdislikes: [],
      eliminateGroupsData:[],
    };
    this.getPrefs();
  }

  getPrefs() {
    const {user} = this.props; 
    this.props.socket.emit('profile', {type: 'getForDashboard', data: {
          'subtype': 'getPrefs', 
          'userId': user.user_id,
        } 
    });
  }

  componentWillMount() {
    setTimeout(() => this.setState({ xyz: true }), 10);
    this.props.socket.on('profile', this.listeners);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('profile', this.listeners);
  }
  componentDidMount(){
    const {dataArray} = this.props;
    const {dishGroups} = dataArray;
    let eliminateGroupsData = [];
    let ingredientList1 = Object.keys(dishGroups).sort((a,b)=>{
      const nameA = dishGroups[a].toUpperCase(); // ignore upper and lowercase
      const nameB = dishGroups[b].toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    
    ingredientList1.map((val)=>{
      eliminateGroupsData.push({label: dishGroups[val], value: String(val)});
    });
    this.setState({eliminateGroupsData});
  }
    listeners = action => {
      const {dataArray} = this.props;
      const { dishGroups } =dataArray;
      switch (action.type) {
        case 'getPrefsOk':
          var eliminate_groups = (action.data.eliminate_groups)&&action.data.eliminate_groups.map((value, idx) => {
            return ({ value:String(value), label: dishGroups[value] });
          });
          this.setState({
            eliminate_groups: eliminate_groups,
            allergies:action.data.allergies
          });

        break;
      }    
  };

  changeEliminateGroups(name, value) {
    this.setState({
      [name]: value
    });
  }


  setNotification(changes, thetype) {
    const {user} = this.props;
    this.props.socket.emit('profile', {type: 'getForDashboard', data: {
          'subtype': 'setNotification', 
          'userId': user.user_id,
          'changes': changes,
          'thetype': thetype,
        } 
    });
  }

  saveDislike() {
    const { eliminate_groups } = this.state;
   
    const dislikesValues =eliminate_groups&&eliminate_groups.map((value, idx) => {
        return value.value;
    });
    const {user} = this.props;
    var data = {
          'subtype': 'saveEliminateGroups', 
          'userId': user.user_id,
          'eliminate_groups': dislikesValues
    }
    this.props.socket.emit('profile', {type: 'getForDashboard', data: data});
    this.setNotification('Food Preferences Dislikes', 1);
  }
 
  render() {
    const { needCloseButtons, buttons, headLine, type, close } = this.props;
    const { xyz } = this.state;
    const {eliminate_groups, eliminateGroupsData} = this.state;
    // const { dataArray} = this.props;
    // const { dishGroups } = dataArray;
    // let eliminateGroups = [];
    // eliminate_groups!=undefined && eliminate_groups && eliminate_groups.map((val)=>{
    //     eliminateGroups.push({label: dishGroups[val], value: parseInt(val)});
    // });
    const className ='select2 select2-container select2-container--default   select2-container--below select2-container--focus';
   
    var message = (<div>
              
              
              <div className="popup-select-area">
                <b className="food-prefs-header">Groups Eliminated</b>
                
                <div className={className}>
                <Select
                  multiple={true}
                  isgrouped={true}
                  placeholder='Change Eliminate'
                  list={eliminateGroupsData}
                  value={eliminate_groups || []}
                  isSearchable={true}
                  name='eliminate_groups'
                  onChange={ (name, val) => {
                    // console.log(val);
                    this.changeEliminateGroups('eliminate_groups', val);
                  }}
                />
              </div> 
              
              </div>
             
                <p align="center">
                    <PaperButton className='button-regular next transition' 
                      onClick={ (e) => {this.saveDislike(); }} >
                        <span>Save</span>
                    </PaperButton>
                </p>
            </div>);









    const listButtons = buttons.map((button, key) =>
      <div className={`d-flex justify-content-center mb-3 transition ${button.className}`} key={key}>
        <input type='button' className={`modal-button ${button.type}`} value={button.label} onClick={() => button.onClick(button.value)} />
      </div>
    );
    const DislikeModalHeadLineOuther = (headLine) =>
      <div className='row modal-header-styled this-height-fix'>
        <div className='col-12 title-block'>
          <p className={'w-100'}><span className='ar-golr-l' />
            <span className='m-title'>{headLine}</span>
            <span className='ar-golr-r' /></p>
        </div>
      </div>;
    const DislikeModalMessageOuther = (message) =>
      <div className='row'>
        <div className='col-12'>
          {message}
        </div>
      </div>;
    return (
      <div className={`${xyz ? 'xyz-fin' : 'xyz'} modal-back-layer ttt`}>
        <div className={`row justify-content-around align-items-center modal-father model-type-${type}`} onClick={() => close()}>
          <div className='modal-custom-body b-rounded-blue personal-shadow col-sm-10 col-md-6 col-lg-4 px-0' onClick={e => e.stopPropagation()}>
            {needCloseButtons && <div className='d-flex justify-content-end align-items-center px-3 close'>
              <i onClick={() => close()} className='icon-close letter-normal mt-2 pt-1 mb-2 pointer' />
            </div>}
            <div className='text-center modal-message pop-style dark-text w-100 letter-sm px-3'>
              {DislikeModalHeadLineOuther(headLine || 'Meal Plan')}
              {DislikeModalMessageOuther(message)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EliminateGroupsModal.propTypes = {
  close: PropTypes.func.isRequired,
  headLine: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired,
  type: PropTypes.oneOf([
    'error',
    'success',
    'info'
  ]),
  needButtons: PropTypes.bool,
  needCloseButtons: PropTypes.bool,
  buttons: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.oneOf([
      'error',
      'success',
      'close',
      'info'
    ]),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool
    ]),
    onClick: PropTypes.func,
    className: PropTypes.string
  }))
};

EliminateGroupsModal.defaultProps = {
  type: 'success',
  needButtons: false,
  needCloseButtons: true,
  buttons: []
};

export default EliminateGroupsModal;