import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import {ABU_CENTER, MAPS_API_KEY} from 'variables/constants';
import './marker_style.css';

const SetMarker = (props) => {
  const {hoveredAtTable, title, hint, style, marker_name, setType, edit, edit_name} = props;
  var {image, image_selected} = props;
  const height = 36;
  const width = height / 1.33;
  const left = -width / 2; 
  const top = -height;

  if (edit && marker_name == edit_name) {
    image = image_selected;
  }

  return( 
    <div
      className='marker-holder'
      style={{
        width: `${width}px`,
        height: `${height}px`,
        left: `${left}px`,
        top: `${top}px`,
        backgroundImage: `url(${image})`,
        zIndex: hoveredAtTable ? 11000 : 6500,
        ...style
      }}
     // onMouseEnter={this.props.onMouseEnter}
     // onMouseLeave={this.props.onMouseLeave}
     onClick={() => setType(marker_name)}
    >
      <p className='marker-title'>
        {title}
      </p>
    </div>
  )
}

class SimpleMap extends Component {

  constructor(props) {
    super(props);
   
    this.state = {
      edit: false,
      type: null,
    };
  }

  static defaultProps = {
    center: ABU_CENTER,
    zoom: 11
  }; 

  setType = (name) => {
    this.setState({
      edit: true,
      type: name,
    });
  }

  changeLocation = ({ lat, lng }, edit, type, addressesChange) => {
  console.log(lat)
  console.log(lng);
    if (edit) {
      console.log('can_edit');
      console.log(type);

      this.setState({
            type: null,
            edit: false,
      });

      addressesChange(lat, lng, type);
      
      // const geo = new this.maps.Geocoder();
      // const location = new this.maps.LatLng(lat, lng);
      // geo.geocode({ location }, (res, status) => {
      //   if (status === 'OK') {
      //     console.log(res);
      //   }
      // });
    }
  };

  render() {
    const {marker, marker_selected, addressesChange, home, work, pub, homeActive, workActive, pubActive} = this.props;
    const {edit, type} = this.state;

    var homeMarker = '';
    var workMarker = '';
    var pubMarker = '';

    if (homeActive) {
      homeMarker = (
          <SetMarker
            lat={home.lat}
            lng={home.lng}
            title="H"
            image={marker}
            image_selected={marker_selected}
            marker_name='home'
            setType={this.setType}
            edit={edit}
            edit_name={type}
          />
      );
    }

    if (workActive) {
      workMarker = (
          <SetMarker
            lat={work.lat}
            lng={work.lng}
            title="W"
            marker_name='work'
            image={marker}
            image_selected={marker_selected}
            setType={this.setType}
            edit={edit}
            edit_name={type}
          />
      );
    }

    if (pubActive) {
      pubMarker = (
        <SetMarker
            lat={pub.lat}
            lng={pub.lng}
            title="P"
            marker_name='pub'
            image={marker}
            image_selected={marker_selected}
            setType={this.setType}
            edit={edit}
            edit_name={type}
        />
      );
    }

    return ( 
      // Important! Always set the container height explicitly
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBEdvNdAlB2eDMxEv-1X34IPRQ071OBjBI' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          onClick={(e) => this.changeLocation(e, edit, type, addressesChange)}
        >

        {pubMarker}
        {workMarker}
        {homeMarker}

        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap